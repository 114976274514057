import { PageLayoutWidgetDto } from "@lib/ShiOneClient";

import React, { ReactNode } from "react";
import { WidgetWrapper } from "shared-ui";
import MuiDataGrid from "../../../../components/mui-data-grid/MuiDataGrid";
import { widgetTableTheme } from "shared-ui/src/widgets/framework/widgetTableTheme";

export const RankingWidgetContainerHeight = 320;

export default function RankingWidget({
  pageLayoutWidget,
  headerAction,
  data,
  gridDefinitions,
  getRowId,
  treeData,
  rowExpandLookup,
  noDataElement,
  loading,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
  headerAction?: ReactNode;
  data: any;
  gridDefinitions: any;
  getRowId?: (row: any) => string;
  treeData?: boolean;
  rowExpandLookup: any;
  noDataElement: ReactNode;
  loading: boolean;
}) {
  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      noPadding={true}
      headerAction={headerAction}
      isEmpty={data?.length === 0 && !loading}
    >
      {data?.length > 0 || loading ? (
        <MuiDataGrid
          autoHeight={false}
          loading={loading}
          containerStyle={{ height: RankingWidgetContainerHeight }}
          gridData={data ?? []}
          gridDefinitions={gridDefinitions}
          getRowId={getRowId}
          pagination={false}
          hideFooter={true}
          adjustRowHeight={false}
          customTheme={{
            ...widgetTableTheme(),
            "& .MuiDataGrid-cell:has(+ :not(.MuiDataGrid-cell))": {
              padding: 0,
              "& div": {
                paddingRight: ".5rem",
                textAlign: "right",
                justifyContent: "flex-end",
              },
            },
            "& .MuiDataGrid-cell:last-child": {
              padding: 0,
              "& div": {
                paddingRight: ".5rem",
                textAlign: "right",
                justifyContent: "flex-end",
              },
            },
          }}
          treeData={treeData}
          rowExpandLookup={rowExpandLookup}
        />
      ) : (
        noDataElement
      )}
    </WidgetWrapper>
  );
}
