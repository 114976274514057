import { Theme } from "@mui/material";

export const NavigationStyles = (theme: Theme) => ({
  drawer: {
    "& .MuiPaper-root": {
      zIndex: 1000,
      backgroundColor: theme.palette.background.dark,
      width: "250px",
      borderRadius: 0,
      [theme.breakpoints.up("lg")]: {
        marginTop: "50px",
        height: "calc(100% - 50px)",
      },
      "& svg": {
        fontSize: "1.25em",
      },
    },
  },
  shiDrawer: {
    [theme.breakpoints.up("lg")]: {
      height: "calc(100% - 50px)",
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  menuButton: {
    maxHeight: "50px",
  },
  navHeader: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    height: "50px",
    borderBottom: "1px solid #dee2e6",
  },
  navAction: {
    float: "left",
  },
  navLogo: {
    overflow: "hidden",
    padding: "5px 12px 5px 0",
  },
  navImage: {
    maxHeight: "40px",
    maxWidth: "200px",
  },
  list: {
    height: "calc(100% - 50px)",
    overflowY: "auto",
    flex: "1 1",
  },
  shiAccountSelector: {
    backgroundColor: theme.palette.background.paper,
    "& .MuiOutlinedInput-root": {
      borderRadius: "0 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
  iconReturn: {
    color: theme.palette.secondary.main,
  },
});

export const NavFunctionStyles = (theme: Theme) => ({
  listItemText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginRight: 0,
    "& > span": {
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "14px",
    },
  },
  listItemIcon: {
    marginRight: 0,
    color: theme.palette.text.primary,
    minWidth: theme.spacing(4),
    "& > span": {
      fontSize: "1em",
      width: "auto",
    },
  },
  nested: {
    paddingLeft: theme.spacing(6),
    "& div > span": {
      fontSize: "14px",
    },
  },
  nested2: {
    paddingLeft: theme.spacing(8),
    "& div > span": {
      fontSize: "14px",
    },
  },
});

export const NavGroupStyles = (theme: Theme) => ({
  listItemText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginRight: 0,
    "& > span": {
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "14px",
    },
  },
  listItemIcon: {
    marginRight: 0,
    color: theme.palette.text.primary,
    minWidth: theme.spacing(4),
    fontSize: "1rem",
    "& > span": {
      fontSize: "1rem",
      width: "auto",
    },
  },
  tier2: {
    paddingLeft: theme.spacing(4),
    "& > span": {
      fontSize: "14px",
    },
  },
});

export const NavLinkStyles = (theme: Theme) => ({
  listItemText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginRight: 0,
    "& > span": {
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "14px",
    },
  },
  listItemIcon: {
    marginRight: 0,
    color: theme.palette.text.primary,
    minWidth: theme.spacing(4),
    "& > span": {
      fontSize: "1em",
      width: "auto",
    },
  },
  nested: {
    paddingLeft: theme.spacing(6),
    "& div > span": {
      fontSize: "14px",
    },
  },
  nested2: {
    paddingLeft: theme.spacing(8),
    "& div > span": {
      fontSize: "14px",
    },
  },
});
