import { Icon } from "@mui/material";
import React from "react";
import { NavItemType } from "../../NavigationTypes";
import { servicesItemsToShowType } from "../../NavItemsToShowTypes";

export function CustomerServicesMenuV2(
  servicesItemsToShow: servicesItemsToShowType,
  fsaID: number | undefined
): NavItemType {
  return {
    id: "services",
    type: "Dashboard",
    displayName: "Services",
    icon: <Icon className={"fas fa-hand-holding-heart"} />,
    visible: true,
    path: "/services",
    children: [
      {
        id: "servicesAllProjects",
        type: "Link",
        displayName: "Projects",
        path: "/services/projects",
        visible: servicesItemsToShow.showAllProjects,
      },
      {
        id: "servicesAllContracts",
        type: "Link",
        displayName: "Contracts",
        path: "/services/contracts",
        visible: servicesItemsToShow.showContracts,
      },
      {
        id: "servicesInvoices",
        type: "Link",
        displayName: "Billing",
        path: "/services/billing",
        visible: servicesItemsToShow.showInvoices,
      },
      {
        id: "servicesFSA",
        type: "Link",
        displayName: "Flexible Service Agreement",
        path: "/services/contracts/" + fsaID,
        visible: servicesItemsToShow.showFlexibleServiceAgreement,
      },
    ],
  };
}
