import { RecordsDetailContainerProps } from "@features/projects-feature/projectTypes";
import { Grid } from "@mui/material";
import { RecordsDetailItem } from "@features/projects-feature/components/RecordsDetailItem";
import React from "react";
import { StyledRecordsDetailContainer } from "@features/projects-feature/utils/components/StyledRecordsDetailComponents";

export const RecordsDetailContainer = ({
  recordsDetail,
}: RecordsDetailContainerProps) => {
  return (
    <StyledRecordsDetailContainer container spacing={2}>
      {recordsDetail.map((detail, index) => (
        <Grid key={detail.title || index} item xs={4}>
          <RecordsDetailItem
            {...detail}
          />
        </Grid>
      ))}
    </StyledRecordsDetailContainer>
  );
};
