// DO NOT EDIT THIS FILE! This file is only to be updated by running
// 'yarn get-feature-defs'. Modifying this file can break metrics collection.
export const FeatureDefinitions = {
  Assessments_SecurityPostureReview: 101,
  AssetManagement_AssetContracts: 97,
  AssetManagement_AssetInventory: 95,
  AssetManagement_AssetLifecycle: 96,
  AssetManagement_AssetLocations: 98,
  AssetManagement_AssetProductCatalog: 99,
  AssetManagement_LiveOpticsPropensity: 100,
  Auth_Auth: 1,
  Auth_OnboardingWizard: 5,
  Auth_SAMLLogin: 4,
  Auth_SHIcomLogin: 2,
  Auth_UserRegistration: 3,
  AutomationPlatform_AWSOnboarding: 11,
  AutomationPlatform_AzureOnboarding: 12,
  AutomationPlatform_COSSCOMMs: 10,
  AWS_Billing: 20,
  AWS_Blueprints: 15,
  AWS_CloudAccountsandSubscriptions: 16,
  AWS_CloudInvoicing: 17,
  AWS_CloudMonitoringAlerts: 13,
  AWS_CostAllocationTags: 18,
  AWS_CostandUsageReporting: 19,
  AWS_Governance: 14,
  Azure_Backup: 22,
  Azure_Blueprints: 24,
  Azure_CloudAccountsandSubscriptions: 25,
  Azure_CloudInvoicing: 26,
  Azure_CloudMonitoringAlerts: 21,
  Azure_CostandUsageReporting: 27,
  Azure_Governance: 23,
  Azure_IaC: 90,
  Cisco_CiscoContracts: 30,
  Cisco_CiscoFullInventory: 28,
  Cisco_CiscoLifeCycle: 29,
  Cisco_CiscoOverview: 80,
  Cisco_CiscoSiteLocations: 31,
  Dashboard_HomeDashboard: 34,
  Dashboard_OrganizationProfile: 35,
  Dashboard_UserProfile: 36,
  Datadog_DatadogReports: 37,
  EndUserDevices_DeviceManagement: 38,
  ErrorHandling_InvalidURL: 6,
  GCP_CloudAccountsandSubscriptions: 39,
  GCP_CloudInvoicing: 40,
  GCP_CostandUsageReporting: 41,
  HardwareAssets_AXWarehouseInventory: 42,
  HardwareAssets_AXWarehouseOrders: 43,
  HardwareAssets_HardwareLifecycleManagement: 84,
  HardwareAssets_RackandStack: 44,
  Integrations_IntegrationSettings: 45,
  Integrations_SHILabs: 78,
  Knowledgebase_Knowledgebase: 46,
  Knowledgebase_KnowledgebaseManagement: 47,
  Mindspark_AccountPlan: 88,
  Mindspark_AIServices: 91,
  Mindspark_Coaching: 86,
  Mindspark_MindsparkApp: 85,
  Mindspark_MSP: 89,
  Mindspark_TripReports: 87,
  Monitoring_CloudMonitoringAlerts: 48,
  MulticloudConsumption_CostandUsageReporting: 49,
  MulticloudConsumption_CostCenterSettings: 50,
  Office365_CloudAccountsandSubscriptions: 51,
  Office365_CloudInvoicing: 53,
  Office365_SkykickBackup: 52,
  OperationalExcellence_ALZMigration: 92,
  OperationalExcellence_Housekeeping: 93,
  OperationalExcellence_Optimizations: 94,
  Other_InvalidURL: 7,
  Other_Other: 8,
  Other_Redirect: 9,
  ProjectManagement_Contracts: 54,
  ProjectManagement_Invoices: 55,
  ProjectManagement_Projects: 56,
  ServiceRequests_AccountServiceRequests: 57,
  ServiceRequests_AttachmentViewer: 58,
  ServiceRequests_CloudOnboarding: 83,
  ServiceRequests_UserServiceRequests: 60,
  ServiceRequests_UserServiceRequestSubmission: 59,
  Settings_SecurityGroups: 61,
  Settings_Settings: 62,
  Settings_UserManagement: 66,
  SHIInternal_AccountManagement: 63,
  SHIInternal_AccountOnboarding: 64,
  SHIInternal_AzureDirectMapping: 73,
  SHIInternal_CiscoIBA: 81,
  SHIInternal_CiscoPropensityReport: 71,
  SHIInternal_CiscoRenewals: 33,
  SHIInternal_CostandUsageReporting: 74,
  SHIInternal_MyCustomerDashboard: 67,
  SHIInternal_Projects: 72,
  SHIInternal_ServiceRequestContractMapping: 68,
  SHIInternal_ServiceRequestMapping: 65,
  SHIInternal_ServicesandProjects: 69,
  SHIInternal_SuccessPlans: 70,
  SHIOneDemos_SHIDemos: 75,
  Unknown_Unknown: 76
}