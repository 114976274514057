import { ApiClientConfig } from "auth/ApiClientConfig";
import { FeatureFlagClient } from "lib/ShiOneClient";
import { CancelToken } from "axios";

const api = new FeatureFlagClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestFeatureFlagAccess(
  featureFlag: string,
  cancelToken?: CancelToken | undefined
): Promise<boolean> {
  return api.validateFeatureIsEnabled(featureFlag, cancelToken);
}
