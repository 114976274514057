import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { AppendIfPrefixExists } from "../../modules/sitePaths";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutComponent = () => {
  const { logout, isAuthenticated } = useAuth0();
  logout({
    logoutParams: {
      returnTo: (window as any).config.REACT_APP_AUTH0_LOGOUT_URL,
    },
  });
  if (!isAuthenticated) {
    return <Navigate to={AppendIfPrefixExists("/dashboards/home")} />;
  }
  return <Loading />;
};
