import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AppendIfPrefixExists } from "../../../../../modules/sitePaths";
import { RecordsDetail } from "@features/projects-feature/projectTypes";
import {
  StyledRecordsDetailItemContainer,
  StyledRecordsDetailItemCount,
  StyledRecordsDetailItemLinkTitle,
  StyledRecordsDetailItemTitle,
} from "@features/projects-feature/utils/components/StyledRecordsDetailComponents";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";
import React from "react";

export const RecordsDetailItem = ({
                                    title,
                                    count,
                                    linkTo,
                                    contractName
                                  }: RecordsDetail) => {

  function fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  const encodedLinkTo = linkTo + fixedEncodeURIComponent(contractName);

  const forceReloadInvoicesLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (count > 0 && encodedLinkTo.includes('/services/billing')) {
      window.location.href = AppendIfPrefixExists(encodedLinkTo);
    }
  };

  return (
    <StyledRecordsDetailItemContainer>
      <StyledRecordsDetailItemCount variant="h6">
        {numberFormatter.format(count)}
      </StyledRecordsDetailItemCount>
      <StyledRecordsDetailItemTitle variant="body1">
        {title}
      </StyledRecordsDetailItemTitle>
      <Link
        component={RouterLink}
        to={count > 0 ? AppendIfPrefixExists(encodedLinkTo) : ""}
        underline={"none"}
        onClick={forceReloadInvoicesLink}
      >
        <StyledRecordsDetailItemLinkTitle count={count}>
          VIEW RECORDS
        </StyledRecordsDetailItemLinkTitle>
      </Link>
    </StyledRecordsDetailItemContainer>
  );
};
