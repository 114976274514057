import _ from "lodash";

export const getPicklistValue = (picklist, entityType, name, id) => {
  const targetPicklist = _.find(picklist, (p) => {
    return p.entityType.toUpperCase() === entityType.toUpperCase();
  });

  const targetOptions = _.find(targetPicklist?.fields, (p) => {
    return p.name.toUpperCase() === name.toUpperCase();
  });

  if (!targetOptions) {
    return {
      label: "",
    };
  }

  const picklistValue = _.find(targetOptions.picklistValues, (plv) => {
    return plv.value.toString().toUpperCase() === id?.toString().toUpperCase();
  });

  if (!picklistValue) {
    return {
      label: "",
    };
  }

  return picklistValue;
};

export const getPicklistValues = (picklist, entityType, name) => {
  const targetPicklist = _.find(picklist, (p) => {
    return p.entityType.toUpperCase() === entityType.toUpperCase();
  });

  return _.find(targetPicklist.fields, (p) => {
    return p.name.toUpperCase() === name.toUpperCase();
  });
};

export const getPicklistValueByLabel = (
  picklist,
  entityType,
  fieldName,
  label
) => {
  const values = getPicklistValues(picklist, entityType, fieldName);
  const { picklistValues } = values;
  const picklistValue = picklistValues.find((iss) => iss.label === label);

  return picklistValue;
};

export const getPicklistValuesByParentValue = (
  picklist,
  entityType,
  fieldName,
  parentValue
) => {
  const values = getPicklistValues(picklist, entityType, fieldName);
  const { picklistValues } = values;

  return picklistValues.filter((iss) => iss.parentValue === parentValue);
};

export const getPicklistValuesByIds = (picklist, entityType, name, ids) => {
  const targetPicklist = _.find(picklist, (p) => {
    return p.entityType.toUpperCase() === entityType.toUpperCase();
  });

  const picklistValues = _.find(targetPicklist.fields, (p) => {
    return p.name.toUpperCase() === name.toUpperCase();
  }).picklistValues;

  return picklistValues.filter((pv) => ids.indexOf(parseInt(pv.value)) >= 0);
};
