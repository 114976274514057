import { AppThunk } from "../../../store";
import {
  getRestAllCustomerSuccessPlans,
  getRestCustomerSuccessPlan,
  getRestMilestones,
  postRestCustomerActivity,
  postRestCustomerProfile,
  postRestCustomerSuccessPlan,
  deleteRestSuccessPlanActivityDocument,
  postRestUpdateCustomerSuccessPlanStartDate,
} from "../../../api/successPlanStore";
import { successPlanState } from "./successPlanTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CustomerSuccessPlan,
  CustomerSuccessPlanActivity,
  CustomerSuccessPlanActivityRequest,
  CustomerSuccessPlanCreationRequest,
  CustomerSuccessPlanProfileField,
  CustomerSuccessPlanProfileRequest,
  SuccessPlanMilestone,
  SuccessPlanActivityDocument,
} from "lib/ShiOneClient";

import _ from "lodash";

const initialState: successPlanState = {
  addingFiles: "",
  currentCustomer: -1,
  allCustomerSuccessPlansStatus: "idle",
  allCustomerSuccessPlans: [],
  createCustomerSuccessPlanStatus: "idle",
  createCustomerSuccessPlanError: "",
  updateCustomerProfileStatus: "idle",
  updateCustomerProfileError: "",
  updateSuccessPlanActivityStatus: "idle",
  updateSuccessPlanActivityError: "",
  milestones: [],
  milestonesStatus: "idle",
  milestonesError: "",
  lastCreatedPlan: {},
  updateSuccessPlanStartDateStatus: "idle",
};

const findAndChangeActivity = (
  activities: CustomerSuccessPlanActivity[],
  action: PayloadAction<CustomerSuccessPlanActivity>
): void => {
  let index = activities.findIndex(
    (activity) =>
      activity.successPlanActivityId === action.payload.successPlanActivityId
  );

  if (index !== -1) {
    activities[index].documents = action.payload.documents;
    activities[index].complete = action.payload.complete;
    activities[index].dateModified = action.payload.dateModified;
    activities[index].modifiedByUser = action.payload.modifiedByUser;
  } else {
    _.forEach(activities, (activity) => {
      if (activity.children) {
        index = activity.children.findIndex(
          (child) =>
            child.successPlanActivityId === action.payload.successPlanActivityId
        );
        if (index !== -1) {
          activity.children[index].documents = action.payload.documents;
          activity.children[index].complete = action.payload.complete;
          activity.children[index].dateModified = action.payload.dateModified;
          activity.children[index].modifiedByUser =
            action.payload.modifiedByUser;
        } else {
          findAndChangeActivity(activity.children, action);
        }
      }
    });
  }
};

const successPlanSlice = createSlice({
  name: "successPlan",
  initialState,
  reducers: {
    updateCurrentCustomer(state, action: PayloadAction<number>) {
      state.currentCustomer = action.payload;
    },
    receivingAllCustomerSuccessPlans(state) {
      state.allCustomerSuccessPlansStatus = "loading";
      state.createCustomerSuccessPlanStatus = "idle";
    },
    receivedCustomerSuccessPlan(
      state,
      action: PayloadAction<CustomerSuccessPlan>
    ) {
      state.allCustomerSuccessPlans = new Array(action.payload);
      state.allCustomerSuccessPlansStatus = "complete";
    },
    receivedAllCustomerSuccessPlans(
      state,
      action: PayloadAction<CustomerSuccessPlan[]>
    ) {
      state.allCustomerSuccessPlans = action.payload;
      state.allCustomerSuccessPlansStatus = "complete";
    },
    receiveAllCustomerSuccessPlansError(state) {
      state.allCustomerSuccessPlansStatus = "error";
    },
    creatingCustomerSuccessPlan(state) {
      state.createCustomerSuccessPlanStatus = "loading";
    },
    createdCustomerSuccessPlan(
      state,
      action: PayloadAction<CustomerSuccessPlan>
    ) {
      state.allCustomerSuccessPlans = [
        ...state.allCustomerSuccessPlans,
        action.payload,
      ];
      state.createCustomerSuccessPlanStatus = "complete";
      state.lastCreatedPlan = action.payload;
    },
    createCustomerSuccessPlanError(state, action: PayloadAction<string>) {
      state.createCustomerSuccessPlanStatus = "error";
      state.createCustomerSuccessPlanError = action.payload;
    },
    resetCreationStatus(state) {
      state.createCustomerSuccessPlanStatus = "idle";
    },
    updatingCustomerProfile(state) {
      state.updateCustomerProfileStatus = "loading";
    },
    updatedCustomerProfile(
      state,
      action: PayloadAction<CustomerSuccessPlanProfileField[]>
    ) {
      const index = state.allCustomerSuccessPlans.findIndex(
        (plan) => plan.id === action.payload[0].customerSuccessPlanId
      );

      if (index !== -1) {
        state.allCustomerSuccessPlans[index] = Object.assign(
          {},
          {
            ...state.allCustomerSuccessPlans[index],
            customerSuccessPlanProfiles: action.payload,
          }
        );
      }
      state.updateCustomerProfileStatus = "complete";
    },
    updateCustomerProfileError(state, action: PayloadAction<string>) {
      state.updateCustomerProfileStatus = "error";
      state.updateCustomerProfileError = action.payload;
    },
    updatingSuccessPlanActivity(state) {
      state.updateSuccessPlanActivityStatus = "loading";
    },
    updatingSuccessPlanActivityComplete(state) {
      state.updateSuccessPlanActivityStatus = "success";
    },
    updatedSuccessPlanActivity(
      state,
      action: PayloadAction<CustomerSuccessPlanActivity>
    ) {
      const successPlan = _.find(state.allCustomerSuccessPlans, (csp) => {
        return csp.id === action.payload.customerSuccessPlanId;
      });

      if (successPlan) {
        findAndChangeActivity(
          successPlan.customerSuccessPlanActivities as CustomerSuccessPlanActivity[],
          action
        );
      }

      state.updateSuccessPlanActivityStatus = "complete";
    },
    updateSuccessPlanActivityError(state, action: PayloadAction<string>) {
      state.updateSuccessPlanActivityStatus = "error";
      state.updateSuccessPlanActivityError = action.payload;
    },
    receivingMilestones(state) {
      state.milestonesStatus = "loading";
    },
    receivedMilestones(state, action: PayloadAction<SuccessPlanMilestone[]>) {
      state.milestones = action.payload;
      state.milestonesStatus = "complete";
    },
    milestonesError(state, action: PayloadAction<string>) {
      state.milestonesError = action.payload;
      state.milestonesStatus = "error";
    },
    updatingSuccessPlanStartDate(state) {
      state.updateSuccessPlanStartDateStatus = "loading";
    },
    updateSuccessPlanStartDate(
      state,
      action: PayloadAction<CustomerSuccessPlan>
    ) {
      const index = state.allCustomerSuccessPlans.findIndex(
        (a) => a.id === action.payload.id
      );
      if (index !== -1) {
        state.allCustomerSuccessPlans[index] = action.payload;
      } else {
        state.allCustomerSuccessPlans.push(action.payload);
      }
      state.updateSuccessPlanStartDateStatus = "complete";
    },
    updateSuccessPlanStartDateError(state, action: PayloadAction<string>) {
      state.updateSuccessPlanStartDateStatus = "error";
      state.updateSuccessPlanActivityError = action.payload;
    },
  },
});

export const getCustomerSuccessPlan =
  (id: number): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.receivingAllCustomerSuccessPlans());
    try {
      const customerSuccessPlan = await getRestCustomerSuccessPlan(id);

      if (
        customerSuccessPlan.accountId === 31018 ||
        customerSuccessPlan.accountId === 32071 ||
        customerSuccessPlan.accountId === 32072
      ) {
        customerSuccessPlan.previousMonthCloudConsumptionTotal = 33640.67;
      }

      dispatch(
        successPlanSlice.actions.receivedCustomerSuccessPlan(
          customerSuccessPlan
        )
      );
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(successPlanSlice.actions.receivedAllCustomerSuccessPlans([]));
      } else {
        dispatch(
          successPlanSlice.actions.receiveAllCustomerSuccessPlansError()
        );
      }
    }
  };

export const getAllCustomerSuccessPlans =
  (accountId: number): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.receivingAllCustomerSuccessPlans());
    try {
      const customerSuccessPlans = await getRestAllCustomerSuccessPlans(
        accountId
      );

      _.forEach(customerSuccessPlans, (plan) => {
        if (
          plan.accountId === 31018 ||
          plan.accountId === 32071 ||
          plan.accountId === 32072
        ) {
          plan.previousMonthCloudConsumptionTotal = 33640.67;
        }
      });

      dispatch(successPlanSlice.actions.updateCurrentCustomer(accountId));
      dispatch(
        successPlanSlice.actions.receivedAllCustomerSuccessPlans(
          customerSuccessPlans
        )
      );
    } catch (error: any) {
      if (error.status === 404) {
        dispatch(successPlanSlice.actions.receivedAllCustomerSuccessPlans([]));
      } else {
        dispatch(
          successPlanSlice.actions.receiveAllCustomerSuccessPlansError()
        );
      }
    }
  };

export const createCustomerSuccessPlan =
  (
    accountId: number,
    successPlanRequest: CustomerSuccessPlanCreationRequest
  ): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.creatingCustomerSuccessPlan());
    try {
      const createdSuccessPlan = await postRestCustomerSuccessPlan(
        accountId,
        successPlanRequest
      );
      dispatch(
        successPlanSlice.actions.createdCustomerSuccessPlan(createdSuccessPlan)
      );
    } catch (error: any) {
      dispatch(
        successPlanSlice.actions.createCustomerSuccessPlanError(error.response)
      );
    }
  };

export const updateCustomerSuccessPlanProfile =
  (
    customerSuccessPlanId: number,
    customerProfile: CustomerSuccessPlanProfileRequest
  ): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.updatingCustomerProfile());
    try {
      const updatedCustomerProfile = await postRestCustomerProfile(
        customerSuccessPlanId,
        customerProfile
      );
      dispatch(
        successPlanSlice.actions.updatedCustomerProfile(updatedCustomerProfile)
      );
    } catch (error: any) {
      dispatch(
        successPlanSlice.actions.updateCustomerProfileError(error.message)
      );
    }
  };

export const updateCustomerSuccessPlanActivity =
  (
    customerSuccessPlanId: number,
    activityId: number,
    activityRequest: CustomerSuccessPlanActivityRequest
  ): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.updatingSuccessPlanActivity());
    try {
      const updatedSuccessPlanActivity = await postRestCustomerActivity(
        customerSuccessPlanId,
        activityId,
        activityRequest
      );
      dispatch(
        successPlanSlice.actions.updatedSuccessPlanActivity(
          updatedSuccessPlanActivity
        )
      );
    } catch (error: any) {
      dispatch(
        successPlanSlice.actions.updateSuccessPlanActivityError(error.response)
      );
    }
  };

export const removeSuccessPlanActivityDocument =
  (document: SuccessPlanActivityDocument): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.updatingSuccessPlanActivity());
    try {
      const updatedActivity = await deleteRestSuccessPlanActivityDocument(
        document
      );
      dispatch(
        successPlanSlice.actions.updatedSuccessPlanActivity(updatedActivity)
      );
    } catch (error: any) {
      dispatch(
        successPlanSlice.actions.updateSuccessPlanActivityError(error.response)
      );
    }
  };

export const getMilestones = (): AppThunk => async (dispatch: any) => {
  dispatch(successPlanSlice.actions.receivingMilestones());
  try {
    const milestones = await getRestMilestones();
    dispatch(successPlanSlice.actions.receivedMilestones(milestones));
  } catch (error: any) {
    dispatch(successPlanSlice.actions.milestonesError(error.response));
  }
};

export const resetCreateSuccessPlanStatus =
  (): AppThunk => async (dispatch: any) => {
    dispatch(successPlanSlice.actions.resetCreationStatus());
  };

export const updateSuccessPlanStartDate =
  (customerSuccessPlanId: number, startDate: Date): AppThunk =>
  async (dispatch: any) => {
    dispatch(successPlanSlice.actions.updatingSuccessPlanStartDate());
    try {
      const updatedSuccessPlan =
        await postRestUpdateCustomerSuccessPlanStartDate(
          customerSuccessPlanId,
          startDate
        );
      dispatch(
        successPlanSlice.actions.updateSuccessPlanStartDate(updatedSuccessPlan)
      );
    } catch (error: any) {
      dispatch(
        successPlanSlice.actions.updateSuccessPlanStartDateError(error.response)
      );
    }
  };

export default successPlanSlice.reducer;
