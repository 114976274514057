import { Invoice } from "lib/ShiOneClient";
import {DateTime} from "luxon";

export abstract class DemoAccountIdentifiers {
  static readonly demoAxNumbers: string[] = ["0000000", "9999997", "9999998"];
  static readonly demoAccountIds: number[] = [31018, 32071, 32072];
  static readonly censoredWarehouseAccountIds: number[] = [31018, 32071];
  static readonly censoredCiscoAccountIds: number[] = [31018];
}

export enum CloudType {
  AWS = "AWS",
  Azure = "AZ",
  Office365 = "O365",
  GCP = "GCP",
}

export function getDemoInvoiceData(
  axNumber: string,
  posPrefix: CloudType,
  minAmount: number,
  maxAmount: number,
  baseInvoiceId = 3252600
): Invoice[] {
  const list = new Array<Invoice>(10);
  const poNumberPrefix =
    Math.floor(Math.random() * (19999000 - 10000000)) + 10000000;

  for (let index = 0; index <= 12; index++) {
    list[index] = new Invoice({
      customerPo: posPrefix + "-" + (poNumberPrefix - index),
      invoiceAmount:
        Math.floor(
          Math.random() * (maxAmount * 100 - minAmount * 100) + minAmount * 100
        ) / 100,
      invoiceDate: DateTime.now().plus({days : -1}).plus({months : 0 -index}).toFormat("MM/dd/yyyy"),
      invoiceId: "B" + (baseInvoiceId + index),
      tagFilter: "tag",
      tenant: "demo.shi.com",
      ...(posPrefix === CloudType.GCP
        ? {
            customerRequisition: posPrefix + "-" + (poNumberPrefix - index),
            tag: "GCP",
            serviceProjectName: "",
          }
        : {}),
    });
  }

  return list;
}

export function isDemoAccount(axNumber: string): boolean {
  return DemoAccountIdentifiers.demoAxNumbers.includes(axNumber);
}

export function isDemoAccountByAccountId(accountId: number): boolean {
  return DemoAccountIdentifiers.demoAccountIds.includes(accountId);
}

export function shouldCensorWarehouseData(accountId: number): boolean {
  return DemoAccountIdentifiers.censoredWarehouseAccountIds.includes(accountId);
}
