import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlask } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { NavItemType } from "../../NavigationTypes";

export function LabsMenu(): NavItemType {
  return {
    id: "shiLabs",
    type: "Link",
    displayName: "Labs",
    path: "/labs",
    icon: <FontAwesomeIcon icon={faFlask} />,
    visible: true,
  };
}
