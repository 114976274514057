let currentDate = new Date();
let currentDay = currentDate.getUTCDay();
let currentQuarter = Math.floor(currentDate.getUTCMonth() / 3);
let previousQuarter = currentQuarter === 0 ? 3 : currentQuarter - 1;
let previousQuarterYear =
  previousQuarter === 3
    ? currentDate.getUTCFullYear() - 1
    : currentDate.getUTCFullYear();

export type timeOptionTypes =
  | "This month"
  | "Last month"
  | "This quarter"
  | "Last quarter"
  | "Year to date"
  | "Last year"
  | "1M"
  | "3M"
  | "6M";

export const timeOptionKeys: { [key: string]: timeOptionTypes } = {
  thisMonth: "This month",
  lastMonth: "Last month",
  thisQuarter: "This quarter",
  lastQuarter: "Last quarter",
  yearToDate: "Year to date",
  lastYear: "Last year",
  oneMonth: "1M",
  threeMonth: "3M",
  sixMonth: "6M",
};

export const timeOptions: {
  [key in timeOptionTypes]: { dateFrom: Date; dateTo: Date };
} = {
  "This month": {
    dateTo: currentDate,
    dateFrom: new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      1
    ),
  },
  "Last month": {
    dateTo: new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      0,
      23,
      59,
      59
    ),
    dateFrom: new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth() - 1,
      1
    ),
  },
  "This quarter": {
    dateTo: currentDate,
    dateFrom: new Date(currentDate.getUTCFullYear(), currentQuarter * 3, 1),
  },
  "Last quarter": {
    dateTo: new Date(
      currentDate.getUTCFullYear(),
      currentQuarter * 3,
      0,
      23,
      59,
      59
    ),
    dateFrom: new Date(previousQuarterYear, previousQuarter * 3, 1),
  },
  "Year to date": {
    dateTo: currentDate,
    dateFrom: new Date(currentDate.getUTCFullYear(), 0, 1),
  },
  "Last year": {
    dateTo: new Date(currentDate.getUTCFullYear() - 1, 11, 31, 23, 59, 59),
    dateFrom: new Date(currentDate.getUTCFullYear() - 1, 0, 1),
  },
  "1M": {
    dateTo: currentDate,
    dateFrom: new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth(),
      currentDate.getUTCDate() - currentDay - 28 //current week + previous 4 weeks
    ),
  },
  "3M": {
    dateTo: currentDate,
    dateFrom: new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth() - 2, //current month + previous 2 months
      1
    ),
  },
  "6M": {
    dateTo: currentDate,
    dateFrom: new Date(
      currentDate.getUTCFullYear(),
      currentDate.getUTCMonth() - 5, //current month + previous 5 months
      1
    ),
  },
};
