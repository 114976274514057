import React, { ReactNode } from "react";
import { Box, CardContent, Divider, Typography, useTheme } from "@mui/material";
import { EmptyWidgetImage } from "./commonWidgetUrls";

function ContentError() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: ".5rem",
      }}
    >
      <Typography variant={"h4"}>Error</Typography>
    </Box>
  );
}
export function WidgetContent({
  isEmpty,
  error,
  children,
  noPadding,
  containerSizeInPixels,
}: {
  isEmpty?: boolean;
  error?: boolean;
  children?: ReactNode;
  noPadding?: boolean;
  containerSizeInPixels?: number;
}) {
  const theme = useTheme();

  const style: any = {};

  if (isEmpty) {
    style.backgroundImage = `url(${EmptyWidgetImage})`;
    style.backgroundRepeat = "no-repeat";
    style.backgroundPosition = "center";
    style.backgroundSize = "cover";
  }

  if (containerSizeInPixels) {
    style.maxHeight = containerSizeInPixels;
  }

  const handleChildren = (children: ReactNode | any[]) => {
    if (Array.isArray(children)) {
      return children.map((item, index) => {
        const key = item + index;
        return (
          <div key={key}>
            <div style={{ padding: noPadding ? 0 : theme.spacing(2) }}>
              {item}
            </div>
            {index < children.length - 1 && <Divider />}
          </div>
        );
      });
    }
    return (
      <div style={{ padding: noPadding ? 0 : theme.spacing(2) }}>
        {children}
      </div>
    );
  };
  return (
    <CardContent sx={style}>
      {handleChildren(children)}
      {error && <ContentError />}
    </CardContent>
  );
}
