import MuiButton from "./MuiButton";
import MuiIconButton from "./MuiIconButton";
import MuiListItem from "./MuiListItem";
import MuiListItemText from "./MuiListItemText";
import MuiPaper from "./MuiPaper";
import MuiTableCell from "./MuiTableCell";
import MuiFilledInput from "./MuiFilledInput";
import MuiInputLabel from "./MuiInputLabel";
import MuiCard from "./MuiCard";
import MuiInputBase from "./MuiInputBase";
import MuiTab from "./MuiTab";
import MuiLink from "./MuiLink";
import MuiTabs from "./MuiTabs";
import MuiCircularProgress from "./MuiCircularProgress";
import MuiDialog from "./MuiDialog";
import MuiAppBar from "./MuiAppBar";
// eslint-disable-next-line import/no-anonymous-default-export

const rootElement = document.getElementById("root");

const components = (theme) => ({
  MuiButton: MuiButton(theme, rootElement),
  MuiIconButton: MuiIconButton(rootElement),
  MuiListItemText: MuiListItemText(rootElement),
  MuiListItem: MuiListItem(theme, rootElement),
  MuiCard: MuiCard(rootElement),
  MuiPaper: MuiPaper(theme, rootElement),
  MuiTableCell,
  MuiInputLabel: MuiInputLabel(theme),
  MuiFilledInput,
  MuiInputBase: MuiInputBase(theme, rootElement),
  MuiTabs: MuiTabs(theme, rootElement),
  MuiTab: MuiTab(theme, rootElement),
  MuiLink: MuiLink(theme, rootElement),
  MuiCircularProgress: MuiCircularProgress(rootElement),
  MuiDialog: MuiDialog(rootElement),
  MuiAppBar: MuiAppBar(theme, rootElement),
});
export default components;
