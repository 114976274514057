import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import { SkeletonWrapper } from "../widgets";

const SingleDataWithLabels = ({
  data,
  label,
  loading,
  containerStyle,
  showValue = true,
}: {
  data: any;
  label: string;
  loading?: boolean;
  containerStyle?: any;
  showValue?: boolean;
}) => {
  const theme = useTheme();
  const numberFormatter = new Intl.NumberFormat("en-US");
  return (
    <Grid container sx={[{ width: "100%" }, containerStyle]}>
      <Grid item container xs={7} alignItems="center">
        <SkeletonWrapper loading={loading}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            {label}
          </Typography>
        </SkeletonWrapper>
      </Grid>
      {showValue && (
        <Grid item container xs={5} justifyContent="flex-end">
          <SkeletonWrapper loading={loading}>
            <Typography sx={{ fontSize: "20px", textAlign: "right" }}>
              {typeof data === "number" ? numberFormatter.format(data) : data}
            </Typography>
          </SkeletonWrapper>
        </Grid>
      )}
    </Grid>
  );
};
export default SingleDataWithLabels;
