import StyledDataGrid from "../../v2/src/theme/overrides/StyledDataGrid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useDeleteUserConfig,
  useGetUserConfig,
} from "../../modules/hooks/useUserConfig";
import {
  DataGridProProps,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { SortingOrder } from "./constants";
import { useRegisterDataGridSaveEvents } from "./useRegisterDataGridSaveEvents";
import MuiDataGridToolBar from "./components/MuiDataGridToolBar";
import NoTableData from "../../containers/NoTableData";
import { UserConfigDto } from "@lib/ShiOneClient";
import SkeletonLoadingOverlay from "../SkeletonLoadingOverlay";
import useDataGridStickyHeader from "shared-ui/src/utils/useDataGridStickyHeader";

//This component props is extending the MuiDataGridProps,
// to see all of them, check the DataGridProPropsWithDefaultValue interface in the below line.
interface ShiDataGridProps extends Partial<DataGridProProps> {
  loading?: boolean;
  gridDefinitions: any;
  gridData: any[];
  totalRowCount?: number;
  hideSelectAllCheckBox?: boolean;
  onPageChangeHandler?: (e: { page: number; pageSize: number }) => void;
  onSortChangeHandler?: (sortModel: any) => void;
  getRowId?: (rowData: any) => string;
  adjustRowHeight?: boolean;
  handleRowSelection?: (selectedRowIds: string[]) => void;
  rowExpandLookup?: any;
  apiRef?: any;
  saveUserConfig?: boolean;
  gridId?: string;
  customToolbar?: () => React.ReactElement;
  containerStyle?: any;
  customTheme?: any;
  rowDensityMenu?: boolean;
  exportMenu?: boolean;
  viewSelect?: () => React.ReactElement;
  hideNoTableDataSuggestions?: boolean;
  hideRowHoverBackground?: boolean;
  customNoDataMessage?: string;
}

const MuiDataGrid: React.FC<ShiDataGridProps> = ({
  loading = false,
  gridDefinitions,
  gridData,
  totalRowCount,
  hideSelectAllCheckBox = false,
  onPageChangeHandler,
  onSortChangeHandler,
  adjustRowHeight = true,
  handleRowSelection,
  rowExpandLookup,
  apiRef = null,
  sortingOrder = [SortingOrder.Descending, SortingOrder.Ascending, null],
  saveUserConfig = false,
  gridId = undefined,
  disableRowSelectionOnClick = true,
  customToolbar,
  pagination = true,
  containerStyle,
  customTheme,
  rowDensityMenu,
  exportMenu,
  viewSelect,
  hideNoTableDataSuggestions,
  hideRowHoverBackground,
  customNoDataMessage,
  autoHeight = true,
  ...props
}) => {
  const location = useLocation();
  const configSettingsKey =
    location.pathname + "_MuiGridSettings" + (gridId ? ":" + gridId : "");
  const { userConfig, isLoading } = useGetUserConfig(configSettingsKey);
  const deleteUserConfig = useDeleteUserConfig();
  const localApiRef = useGridApiRef();
  if (!apiRef) apiRef = localApiRef;

  const {
    columnDefinitions,
    pinnedColumns,
    hiddenColumns,
    groupingColDef,
    sortedColumns,
  } = gridDefinitions;
  useDataGridStickyHeader(autoHeight, gridId);

  const [initialState, setInitialState] = useState<any>();
  const [isDefaultView, setIsDefaultView] = useState<boolean>(true);
  const [userConfigSetting, setUserConfigSetting] = useState<UserConfigDto>();

  const hiddenColumnsObject = hiddenColumns?.reduce(
    (object: {}, item: string) => ({ ...object, [item]: false }),
    {}
  );

  const defaultInitialState = {
    columns: {
      columnVisibilityModel: hiddenColumnsObject,
    },
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 25,
      },
    },
    pinnedColumns: pinnedColumns,
    sorting: {
      sortModel: sortedColumns,
    },
  };

  const columns = useMemo<GridColDef>(() => {
    return columnDefinitions.map((columnDefinition: { [x: string]: any }) => {
      return Object.keys(columnDefinition).reduce((acc: any, key) => {
        if (columnDefinition[key] !== undefined) {
          if (key === "id") acc["field"] = columnDefinition[key];
          else acc[key] = columnDefinition[key];
        }
        return acc;
      }, {});
    });
  }, [columnDefinitions]);

  const removeUnusedStates = (gridState: any) => {
    delete gridState.preferencePanel;
    delete gridState.pagination;
    delete gridState.filter;
  };

  useEffect(() => {
    if (!initialState) {
      setInitialState(apiRef.current.exportState());
    }
    if (userConfig?.settingsValue && initialState) {
      const parsedInitialState: any = JSON.parse(userConfig.settingsValue);
      setUserConfigSetting(userConfig);
      removeUnusedStates(parsedInitialState.gridState);
      removeUnusedStates(initialState);

      const isSavedEqualDefault =
        JSON.stringify(parsedInitialState.gridState) ===
        JSON.stringify(initialState);

      if (!isSavedEqualDefault) {
        apiRef.current.restoreState(parsedInitialState.gridState);
      }
      setIsDefaultView(isSavedEqualDefault);
    } else if (!isLoading && !userConfig && initialState) {
      apiRef.current.restoreState(initialState);
      setIsDefaultView(true);
    }
  }, [isLoading, userConfig, initialState]);

  // This hook must be below the two useEffects above that modify the grid
  // to prevent overriding the loaded state
  useRegisterDataGridSaveEvents(apiRef, gridId, saveUserConfig);

  useEffect(() => {
    if (apiRef?.current != null) {
      if (apiRef?.current.subscribeEvent != null) {
        return apiRef?.current?.subscribeEvent(
          "rowExpansionChange",
          (node: any) => {
            rowExpandLookup.current[node.id] = node.childrenExpanded;
          }
        );
      }
    }
  }, []);

  const handleResetTableView = async () => {
    if (initialState && userConfigSetting) {
      deleteUserConfig.mutate(userConfigSetting);
    }
  };
  function applyStickyHeader() {
    if (containerStyle) {
      return containerStyle;
    } else if (gridData.length === 0) {
      return {
        height: "75vh",
      };
    } else return;
  }

  const customNoTableData = () => {
    return (
      <NoTableData
        hideSuggestions={hideNoTableDataSuggestions}
        noDataMessage={customNoDataMessage}
      />
    );
  };

  const isGroupExpanded = useCallback(
    (row: any) => {
      return !!rowExpandLookup.current[row.id];
    },
    [rowExpandLookup]
  );

  const getTreeDataPath = (row: any) => row.hierarchy;
  const dataGridTreeDataProps: any = {};
  if (props.treeData) {
    dataGridTreeDataProps.treeData = true;
    dataGridTreeDataProps.groupingColDef = groupingColDef;
    dataGridTreeDataProps.isGroupExpandedByDefault = isGroupExpanded;
    dataGridTreeDataProps.getTreeDataPath = getTreeDataPath;
  }
  const getSx = () => {
    let sx = {};

    if (hideSelectAllCheckBox) {
      sx = {
        ...sx,
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
          {
            display: "none",
          },
        "& .Mui-checked": {
          color: "#FF6D15 !important",
        },
      };
    }

    if (hideRowHoverBackground) {
      sx = {
        ...sx,
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "transparent",
        },
      };
    }
    if (gridData.length === 0) {
      sx = {
        ...sx,
        "& .MuiDataGrid-virtualScroller": {
          overflow: "hidden",
        },
        "& .MuiDataGrid-scrollbar--horizontal": {
          visibility: "hidden",
        },
      };
    }
    return sx;
  };

  return (
    <div style={{ ...applyStickyHeader(), width: "100%" }}>
      <StyledDataGrid
        {...dataGridTreeDataProps}
        autoHeight={autoHeight && gridData.length > 0}
        key={gridId}
        sx={getSx()}
        apiRef={apiRef}
        loading={loading}
        pagination={pagination}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        columns={columns}
        rows={gridData}
        paginationMode={props.paginationMode ?? "client"}
        onRowSelectionModelChange={handleRowSelection}
        disableColumnFilter={true}
        onPaginationModelChange={onPageChangeHandler}
        onSortModelChange={onSortChangeHandler}
        rowCount={totalRowCount}
        getRowHeight={() => {
          return adjustRowHeight ? "auto" : null;
        }}
        initialState={defaultInitialState}
        pageSizeOptions={[15, 25, 50]}
        customTheme={customTheme}
        density={props.density ?? "standard"}
        slots={{
          loadingOverlay: SkeletonLoadingOverlay,
          toolbar: MuiDataGridToolBar,
          noRowsOverlay: customNoTableData,
        }}
        slotProps={{
          toolbar: {
            customToolbar: customToolbar,
            checkboxSelection: props.checkboxSelection,
            saveUserConfig: saveUserConfig,
            treeData: props.treeData,
            handleResetTableView: handleResetTableView,
            isDefaultView: isDefaultView,
            initialState: initialState,
            viewSelect: viewSelect,
            rowDensityMenu: rowDensityMenu,
            exportMenu: exportMenu,
          },
        }}
        sortingOrder={sortingOrder}
        sortingMode={props.sortingMode ?? "client"}
        {...props}
      />
    </div>
  );
};

export default MuiDataGrid;
