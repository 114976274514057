import GlobalConstants from "@constants";
import { standardGraphColors } from "../theme/siteColors";
import { checkPermissions } from "./securityGroupHelper";
import { getDesiredStartingColorModIndex } from "./colorIndexHelper";

import _ from "lodash";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getIntegrationDefinitionFromTypeId(
  typeId,
  integrationsDefinitions
) {
  const { integrationType } = GlobalConstants;
  const matchingIntegrationType = Object.keys(integrationType).find(
    (key) => integrationType[key] === typeId
  );

  if (!matchingIntegrationType) {
    return null;
  }

  return integrationsDefinitions.find((integrationDefinition) => {
    return (
      integrationDefinition.integrationType.toLowerCase() ===
      matchingIntegrationType.toLowerCase()
    );
  });
}

export function getTypeIdFromIntegrationDefinition(integrationDefinition) {
  const { integrationType } = GlobalConstants;

  const key = Object.keys(integrationType).find((key) => {
    return (
      key &&
      integrationDefinition &&
      integrationDefinition.integrationType &&
      key.toLowerCase() === integrationDefinition.integrationType.toLowerCase()
    );
  });

  if (integrationType[key]) {
    return integrationType[key];
  }

  return null;
}

export function hasIntegrationsByType(integrationsCollection, integrationType) {
  const { integrationsCollectorFetchStatus, integrations } =
    integrationsCollection;
  return (
    integrationsCollectorFetchStatus === "complete" &&
    integrations &&
    integrations.length > 0 &&
    _.some(
      integrations,
      (integration) =>
        integration.integrationType === integrationType &&
        integration.activated === true
    )
  );
}

export function getCloudReportInformation(costData, isAdmin, myTeams) {
  const awsReport = "aws_cost/history";
  const azureReport = "azure_cost/history";
  const gcpReport = "gcp_cost/history";

  const permissions = {
    AWS:
      isAdmin ||
      checkPermissions(myTeams, "cloud/aws/report", "report:ReadReports"),
    Azure:
      isAdmin ||
      checkPermissions(myTeams, "cloud/azure/report", "report:ReadReports"),
    AzureDirect:
      isAdmin ||
      checkPermissions(myTeams, "cloud/azure/report", "report:ReadReports"),
    GCP:
      isAdmin ||
      checkPermissions(myTeams, "cloud/gcp/report", "report:ReadReports"),
  };

  let hasAwsReport = false;
  let hasAzureReport = false;
  let hasAzureDirectReport = false;
  let hasGcpReport = false;
  let fetchCloudReportsComplete = false;

  let awsAccounts = 0;
  let azureSubscriptions = 0;
  let azureDirectSubscriptions = 0;
  let gcpAccounts = 0;

  const skeletonCloudReportChartData = {
    labels: [],
    datasets: [],
  };

  let cloudCurrentCost = 0;
  let awsLastMonthCost = 0;
  let azureLastMonthCost = 0;
  let azureDirectLastMonthCost = 0;
  let gcpLastMonthCost = 0;
  let cloudLastMonthCost = 0;
  let projectedCost = 0;
  let dailyAverageCost = 0;

  const cloudChartJsData = {
    datasets: [],
    labels: [],
  };

  let colorIndex = 0;

  _.forEach(costData.response, (value, key) => {
    const monthlyCosts = [0, 0, 0];
    let hasNonZeroCost = false;

    if (Object.keys(value.accounts).length === 0 || !permissions[key]) {
      return;
    }

    for (let acctKey of Object.keys(value.accounts)) {
      let costAnalytics = value.accounts[acctKey];
      costAnalytics = _.orderBy(costAnalytics, ["month"], ["asc"]);

      const currentMonth = new Date().getMonth() + 1;

      const currentMonthDate = new Date();
      currentMonthDate.setDate(1);

      const lastMonth = new Date();
      lastMonth.setDate(1);
      lastMonth.setMonth(lastMonth.getMonth() - 1);

      const twoMonthsPrior = new Date();
      twoMonthsPrior.setDate(1);
      twoMonthsPrior.setMonth(twoMonthsPrior.getMonth() - 2);

      _.forEach(costAnalytics, (a) => {
        const costDate = new Date(a.year, a.month);
        if (costDate >= twoMonthsPrior) {
          if (costDate >= currentMonthDate) {
            monthlyCosts[2] += a.monthlyCost;
            hasNonZeroCost = true;
          } else if (costDate >= lastMonth) {
            monthlyCosts[1] += a.monthlyCost;
            hasNonZeroCost = true;
            //only want to count accounts for last month
            if (key === "AWS") {
              awsAccounts += a.numberOfAccounts;
            } else if (key === "Azure") {
              azureSubscriptions += a.numberOfAccounts;
            } else if (key === "GCP") {
              gcpAccounts += a.numberOfAccounts;
            } else if (key === "AzureDirect") {
              azureDirectSubscriptions += a.numberOfAccounts;
            }
          } else {
            monthlyCosts[0] += a.monthlyCost;
            hasNonZeroCost = true;
          }

          if (_.indexOf(cloudChartJsData.labels, months[a.month - 1]) === -1) {
            cloudChartJsData.labels.push(months[a.month - 1]);
          }

          if (a.month === currentMonth) {
            projectedCost += a.projectedCost;
            cloudCurrentCost += a.monthlyCost;
            dailyAverageCost += a.dailyAverageCost;
          }
          if (key === "AWS") {
            hasAwsReport = true;
          } else if (key === "Azure") {
            hasAzureReport = true;
          } else if (key === "GCP") {
            hasGcpReport = true;
          } else if (key === "AzureDirect") {
            hasAzureDirectReport = true;
          }

          if (
            a.month === lastMonth.getMonth() + 1 &&
            a.year === lastMonth.getFullYear()
          ) {
            if (key === "AWS") {
              awsLastMonthCost += a.monthlyCost;
            } else if (key === "Azure") {
              azureLastMonthCost += a.monthlyCost;
            } else if (key === "GCP") {
              gcpLastMonthCost += a.monthlyCost;
            } else if (key === "AzureDirect") {
              azureDirectLastMonthCost += a.monthlyCost;
            }

            cloudLastMonthCost += a.monthlyCost;
          }

          fetchCloudReportsComplete = true;
        }
      });
    }
    const cloudCostHistoryStartingColorIndex = 13;
    const cloudCostHistoryColorIndex = getDesiredStartingColorModIndex(
      colorIndex,
      standardGraphColors.length,
      cloudCostHistoryStartingColorIndex
    );

    if (hasNonZeroCost) {
      cloudChartJsData.datasets.push({
        backgroundColor: standardGraphColors[cloudCostHistoryColorIndex],
        hoverBackgroundColor: standardGraphColors[cloudCostHistoryColorIndex],
        data: monthlyCosts,
        label: key === "AzureDirect" ? "Azure Direct" : key,
      });
    }

    colorIndex++;
  });

  const totalAccounts =
    awsAccounts + azureSubscriptions + azureDirectSubscriptions + gcpAccounts;

  const cloudThreeMonthReportChartOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: function (value) {
            return (
              "$" +
              parseFloat(value).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            );
          },
        },
      },
    },
    tooltips: {
      enabled: true,
      mode: "label",
      callbacks: {
        afterTitle: function () {
          window.total = 0;
        },
        label: function (tooltipItems, data) {
          const cloud = data.datasets[tooltipItems.datasetIndex].label;
          window.total += tooltipItems.yLabel;
          return cloud === "AzureDirect"
            ? "Azure Direct"
            : cloud +
                ": $" +
                parseFloat(tooltipItems.yLabel).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
        },
        footer: function () {
          return (
            "Total : $" +
            parseFloat(window.total).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        },
      },
    },
  };

  return {
    awsReport,
    azureReport,
    gcpReport,
    hasAwsReport,
    hasAzureReport,
    hasAzureDirectReport,
    hasGcpReport,
    fetchCloudReportsComplete,
    cloudChartJsData,
    skeletonCloudReportChartData,
    cloudThreeMonthReportChartOptions,
    cloudCurrentCost,
    dailyAverageCost,
    awsLastMonthCost,
    azureLastMonthCost,
    azureDirectLastMonthCost,
    gcpLastMonthCost,
    cloudLastMonthCost,
    projectedCost,
    totalAccounts,
    awsAccounts,
    azureSubscriptions,
    azureDirectSubscriptions,
    gcpAccounts,
  };
}
