import { homeItemsToShowType } from "../../NavItemsToShowTypes";

export default function MultiCloudConsumptionMenu(
  itemsToShow: homeItemsToShowType
) {
  return {
    id: "multicloudMonitoring",
    type: "Link",
    displayName: "Multicloud Consumption",
    path: "/dashboards/multicloud",
    visible: itemsToShow.showMultiCloudConsumption,
  };
}
