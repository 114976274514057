import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../../../store";
import { IntegrationsCollectorState } from "./integrationsCollectorTypes";
import {
  getRestIntegrationAccounts,
  getRestIntegrationDefinitions,
  getRestIntegrationsCollection,
  httpDeleteRegistration,
  postActivateIntegration,
  postDeactivateIntegration,
  postRegisterIntegration,
  postUpdateRegistration,
} from "@api/integrationsCollectorStore";
import {
  CustomerAccount,
  IntegrationDefinition,
  IntegrationDTO,
} from "lib/ShiOneClient";

import _ from "lodash";
import { mapByIntegrationType } from "../../../functions/integrationCreationMapper";
import GlobalConstants from "@constants";

function updateIntegrations(
  integrations: any[],
  integrationsToUpdate: IntegrationDTO[]
) {
  return integrations.map((int) => {
    let matchingIntegration = null;
    integrationsToUpdate.forEach((updatedInt) => {
      if (updatedInt.id === int.id) {
        matchingIntegration = updatedInt;
      }
    });
    return !matchingIntegration ? int : matchingIntegration;
  });
}

const initialState: IntegrationsCollectorState = {
  integrations: [],
  integrationsCollectorFetchStatus: "idle",
  integrationDefinitions: [],
  integrationDefinitionsFetchStatus: "idle",
  activateIntegrationStatus: "idle",
  registerIntegrationStatus: "idle",
  deleteIntegrationStatus: "idle",
  updateIntegrationStatus: "idle",
  deactivateIntegrationStatus: "idle",
  integrationAccountDataFetchStatus: "idle",
  integrationAccountData: [] as CustomerAccount[],
};

const integrationsCollectorSlice = createSlice({
  name: "integrationsCollection",
  initialState,
  reducers: {
    receivingIntegrationsCollection(state) {
      state.integrationsCollectorFetchStatus = "loading";
    },
    receivedIntegrationsCollection(
      state,
      action: PayloadAction<IntegrationDTO[]>
    ) {
      state.integrationsCollectorFetchStatus = "complete";
      state.integrations = action.payload;
    },
    integrationsCollectionError(state) {
      state.integrationsCollectorFetchStatus = "error";
    },
    receivingIntegrationDefinitions(state) {
      state.integrationDefinitionsFetchStatus = "loading";
    },
    receivedIntegrationDefinitions(
      state,
      action: PayloadAction<IntegrationDefinition[]>
    ) {
      state.integrationDefinitionsFetchStatus = "complete";
      state.integrationDefinitions = action.payload;
    },
    integrationDefinitionsError(state) {
      state.integrationDefinitionsFetchStatus = "error";
    },
    activatingIntegration(state) {
      state.activateIntegrationStatus = "loading";
    },
    activatedIntegration(state, action: PayloadAction<IntegrationDTO[]>) {
      state.integrations = updateIntegrations(
        state.integrations,
        action.payload
      );
      state.activateIntegrationStatus = "complete";
    },
    activateIntegrationError(state) {
      state.activateIntegrationStatus = "error";
    },
    deactivatingIntegration(state) {
      state.deactivateIntegrationStatus = "loading";
    },
    deactivatedIntegration(state, action: PayloadAction<IntegrationDTO[]>) {
      state.integrations = updateIntegrations(
        state.integrations,
        action.payload
      );
      state.deactivateIntegrationStatus = "complete";
    },
    deactivateIntegrationError(state) {
      state.deactivateIntegrationStatus = "error";
    },
    registeringIntegration(state) {
      state.registerIntegrationStatus = "loading";
    },
    registeredIntegration(state, action: PayloadAction<IntegrationDTO[]>) {
      state.registerIntegrationStatus = "complete";
      state.integrations = action.payload;
    },
    registerIntegrationError(state) {
      state.registerIntegrationStatus = "error";
    },
    deletingIntegration(state) {
      state.deleteIntegrationStatus = "loading";
    },
    deletedIntegration(state, action: PayloadAction<number>) {
      const integrationsClone = _.cloneDeep(state.integrations);
      const newIntegrations = _.filter(
        integrationsClone,
        (int) => int.id !== action.payload
      );

      state.deleteIntegrationStatus = "complete";
      state.integrations = newIntegrations;
    },
    deleteIntegrationError(state) {
      state.deleteIntegrationStatus = "error";
    },
    updatingIntegration(state) {
      state.updateIntegrationStatus = "loading";
    },
    updatedIntegration(state, action: PayloadAction<IntegrationDTO>) {
      const integrationsClone = _.cloneDeep(state.integrations);
      const index = _.findIndex(
        integrationsClone,
        (int) => int.id === action.payload.id
      );

      if (index !== -1) {
        integrationsClone[index] = action.payload;
      }

      state.integrations = integrationsClone;
      state.updateIntegrationStatus = "complete";
    },
    updateIntegrationError(state) {
      state.updateIntegrationStatus = "error";
    },
    receivingIntegrationAccountData(state) {
      state.integrationAccountDataFetchStatus = "loading";
    },
    receivedIntegrationAccountData(
      state,
      action: PayloadAction<CustomerAccount[]>
    ) {
      state.integrationAccountData = action.payload;
      state.integrationAccountDataFetchStatus = "complete";
    },
    receiveIntegrationAccountDataError(state) {
      state.integrationAccountDataFetchStatus = "error";
    },
  },
});

export const getIntegrationsCollection =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(
      integrationsCollectorSlice.actions.receivingIntegrationsCollection()
    );
    try {
      const integrations = await getRestIntegrationsCollection();
      dispatch(
        integrationsCollectorSlice.actions.receivedIntegrationsCollection(
          integrations
        )
      );
    } catch {
      dispatch(
        integrationsCollectorSlice.actions.integrationsCollectionError()
      );
    }
  };

export const getIntegrationsDefinitions =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(
      integrationsCollectorSlice.actions.receivingIntegrationDefinitions()
    );

    try {
      const integrationDefinitions = await getRestIntegrationDefinitions();
      dispatch(
        integrationsCollectorSlice.actions.receivedIntegrationDefinitions(
          integrationDefinitions
        )
      );
    } catch {
      dispatch(
        integrationsCollectorSlice.actions.integrationDefinitionsError()
      );
    }
  };

export const registerIntegration =
  (integrationDTO: IntegrationDTO): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(integrationsCollectorSlice.actions.registeringIntegration());

    const mappedIntegration = mapByIntegrationType(
      integrationDTO,
      integrationDTO.integrationType
    );

    if (mappedIntegration === undefined) {
      dispatch(integrationsCollectorSlice.actions.registerIntegrationError());
    }

    try {
      await postRegisterIntegration(mappedIntegration as IntegrationDTO);

      const updatedIntegrations = await getRestIntegrationsCollection();
      dispatch(
        integrationsCollectorSlice.actions.registeredIntegration(
          updatedIntegrations
        )
      );
    } catch {
      dispatch(integrationsCollectorSlice.actions.registerIntegrationError());
    }
  };

export const deleteIntegration =
  (integrationId: number): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(integrationsCollectorSlice.actions.deletingIntegration());

    try {
      await httpDeleteRegistration(integrationId);
      dispatch(
        integrationsCollectorSlice.actions.deletedIntegration(integrationId)
      );
    } catch {
      dispatch(integrationsCollectorSlice.actions.deleteIntegrationError());
    }
  };

export const updateIntegration =
  (integrationDTO: IntegrationDTO): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(integrationsCollectorSlice.actions.updatingIntegration());

    const mappedIntegration = mapByIntegrationType(
      integrationDTO,
      integrationDTO.integrationType
    );

    if (mappedIntegration === undefined) {
      dispatch(integrationsCollectorSlice.actions.updateIntegrationError());
    }

    try {
      const updatedIntegration = await postUpdateRegistration(
        mappedIntegration as IntegrationDTO
      );
      dispatch(
        integrationsCollectorSlice.actions.updatedIntegration(
          updatedIntegration
        )
      );
    } catch {
      dispatch(integrationsCollectorSlice.actions.updateIntegrationError());
    }
  };

export const activateIntegration =
  (integrationDTO: IntegrationDTO[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(integrationsCollectorSlice.actions.activatingIntegration());

    try {
      const activatedIntegration = await postActivateIntegration(
        integrationDTO
      );
      dispatch(
        integrationsCollectorSlice.actions.activatedIntegration(
          activatedIntegration
        )
      );
    } catch {
      dispatch(integrationsCollectorSlice.actions.activateIntegrationError());
    }
  };

export const deactivateIntegration =
  (integrationDTO: IntegrationDTO[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(integrationsCollectorSlice.actions.deactivatingIntegration());

    try {
      const activatedIntegration = await postDeactivateIntegration(
        integrationDTO
      );
      dispatch(
        integrationsCollectorSlice.actions.deactivatedIntegration(
          activatedIntegration
        )
      );
    } catch {
      dispatch(integrationsCollectorSlice.actions.deactivateIntegrationError());
    }
  };

export const getIntegrationAccountCustomerData =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(
      integrationsCollectorSlice.actions.receivingIntegrationAccountData()
    );

    try {
      const integrationAccountData = await getRestIntegrationAccounts();

      dispatch(
        integrationsCollectorSlice.actions.receivedIntegrationAccountData(
          integrationAccountData
        )
      );
    } catch {
      dispatch(
        integrationsCollectorSlice.actions.receiveIntegrationAccountDataError()
      );
    }
  };

export const setIntegrationLoading =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(integrationsCollectorSlice.actions.registeringIntegration());
  };

export const manualIntegrationRegister =
  (error: boolean): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (error) {
      dispatch(integrationsCollectorSlice.actions.registerIntegrationError());
    } else {
      const updatedIntegrations = await getRestIntegrationsCollection();
      dispatch(
        integrationsCollectorSlice.actions.registeredIntegration(
          updatedIntegrations
        )
      );
    }
  };

const multicloudIntegrations = [
  GlobalConstants.integrationType.awsBillingReport,
  GlobalConstants.integrationType.azurePlanReport,
  GlobalConstants.integrationType.gcpUsageReport,
];

const azureIntegrations = [
  GlobalConstants.integrationType.azurePlanReport,
  GlobalConstants.integrationType.azureDirectConsumption,
  GlobalConstants.integrationType.azureDevOpsReport,
];

export const selectHasMultiCloudIntegration = (state) =>
  state.integrationsCollection.integrations.some(
    (int: IntegrationDTO) =>
      int.activated && multicloudIntegrations.includes(int.integrationType)
  );

export const selectVisibleAzureCloudReports = (state) => {
  let azurePlan;
  let azureDirect;
  let azureDevOps;
  state.integrationsCollection.integrations.forEach((int: IntegrationDTO) => {
    if (int.activated && azureIntegrations.includes(int.integrationType)) {
      switch (int.integrationType) {
        case GlobalConstants.integrationType.azurePlanReport:
          azurePlan = int;
          break;
        case GlobalConstants.integrationType.azureDirectConsumption:
          azureDirect = int;
          break;
        case GlobalConstants.integrationType.azureDevOpsReport:
          azureDevOps = int;
          break;
        default:
          break;
      }
    }
  });
  return {
    hasAzurePlanReportIntegration: azurePlan,
    hasAzureDirectConsumptionIntegration: azureDirect,
    hasAzureDevOpsReportIntegration: azureDevOps,
  };
};
export default integrationsCollectorSlice.reducer;

// Return the type of the value returned from selectedVisibleAzureCloudReports
export type CloudReportType = ReturnType<typeof selectVisibleAzureCloudReports>;
