import ReplayIcon from "@mui/icons-material/Replay";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/material";

const ResetIconStyles = () => ({
  container: { width: "28px" },
  replayIcon: { height: "inherit", width: "inherit", position: "relative" },
  clearIcon: {
    fontSize: "12px",
    fontWeight: 800,
    position: "absolute",
    top: 14,
    right: 18,
  },
});
export default function ResetIcon() {
  const styles = ResetIconStyles();
  return (
    <Box sx={styles.container}>
      <ReplayIcon sx={styles.replayIcon}></ReplayIcon>
      <CloseOutlinedIcon sx={styles.clearIcon}>X</CloseOutlinedIcon>
    </Box>
  );
}
