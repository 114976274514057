import React from "react";
import { SwitcherAccount } from "@lib/ShiOneClient";
import { isDemoAccountByAccountId } from "./demoData";
import { impersonateAccount } from "auth/clientmanagement";

export const handleImpersonateAccount = (
  e: React.SyntheticEvent,
  account: SwitcherAccount | null
) => {
  e.persist();
  if (account) {
    const messageString = `By clicking OK, you will be accessing '${account.accountName}' with ax number ${account.axNumber}.`;
    const r = window.confirm(messageString);
    if (r) {
      impersonateAccount(
        account.accountId,
        isDemoAccountByAccountId(account.accountId ?? 0)
      );
    }
  }
};
