const palette = {
  common: {
    neutral: "#373f44",
    muted: "#a0abb2",
  },
  primary: {
    contrastText: "#FFFFFF",
    main: "#253746",
    light: "#66737e",
    dark: "#1a2731",
    alt: "#1C64A5",
  },
  secondary: {
    contrastText: "#FFFFFF",
    main: "#FF6D15",
    light: "#D75100",
    dark: "#FF8D49",
    alt: "rgba(0, 103, 177, 0.08)",
  },
  error: {
    contrastText: "#FFFFFF",
    main: "#DE0007",
    light: "#e84d51",
    dark: "#9b0005",
  },
  success: {
    contrastText: "#FFFFFF",
    main: "#55B948",
    light: "#84cd7b",
    dark: "#3e8a35",
  },
  unavailable: {
    main: "#6F7E8C",
    light: "rgba(0,0,0,0.54)",
  },
  info: {
    contrastText: "#FFFFFF",
    main: "#09B3B1",
    light: "#0be5e2",
    dark: "#067977",
  },
  warning: {
    contrastText: "#FFFFFF",
    main: "#FF6D15",
    light: "#ff8d49",
    dark: "#d75100",
  },
  danger: {
    contrastText: "#FFFFFF",
    main: "#DE0007",
    light: "#ff8d91",
    dark: "#a10005",
  },
  text: {
    primary: "#2a3034",
    secondary: "#373f44",
    disabled: "#cad0d4",
    hint: "rgba(0,0,0,0.58)",
  },
  background: {
    default: "#f8f9fa",
    dark: "#E4E9ED",
    paper: "#FFFFFF",
  },

  border: "#dee2e6",
  divider: "#dee2e6",
  red: "223, 49, 65", //#df3141
};

export default palette;
