import React, { Fragment, useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavItem from "./NavItem";
import { NavGroupStyles } from "../styles/NavigationStyles";
import { NavItemType } from "./NavigationTypes";

interface NavGroupProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}

export default function NavGroup({
  navItem,
  tier,
  parentContext,
}: NavGroupProps) {
  const theme = useTheme();
  const styles = NavGroupStyles(theme);
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  if (!navItem.visible) {
    return <Fragment />;
  }

  const telemetryContext = `${parentContext} > ${navItem.displayName}`;

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        data-telemetry-name={telemetryContext}
      >
        {navItem.icon && (
          <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={navItem.displayName}
          sx={[
            tier === 1 ? styles.listItemText : {},
            tier === 2 ? styles.tier2 : {},
          ]}
          title={navItem.displayName}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {navItem.children && navItem.children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navItem.children.map((child, i) => (
              <NavItem
                key={i}
                navItem={child}
                tier={tier + 1}
                parentContext={telemetryContext}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
