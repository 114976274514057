import { StyledDialogContent } from "@features/projects-feature/utils/components/StyledContractDetailsDialogComponents";
import { RecordsDetailContainer } from "@features/projects-feature/components/RecordsDetailContainer";
import { ServiceContractDetailsContainer } from "@features/projects-feature/components/ServiceContractDetailsContainer";
import React from "react";
import { ContractDetailsDialogContentProps } from "@features/projects-feature/projectTypes";

export const ContractDetailDialogContent = ({
  recordsDetail,
  contract,
  picklist,
}: ContractDetailsDialogContentProps) => {
  return (
    <StyledDialogContent>
      <RecordsDetailContainer recordsDetail={recordsDetail} />
      <ServiceContractDetailsContainer
        contract={contract}
        picklist={picklist}
      />
    </StyledDialogContent>
  );
};
