import React from "react";
import {
  AssetByCategory,
  AssetsByUseStateWidgetClient,
} from "@lib/ShiOneClient";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import SingleDataWithLabels from "../../components/SingleDataWithLabels";
import Box from "@mui/material/Box";
import { Skeleton } from "@mui/material";
import shiColors from "shared-ui/src/theme/shiColors";
import DevicesIcon from "@mui/icons-material/Devices";
import { categoryColors } from "@features/assets-feature/utils/assetsColors";
import { BaseWidgetProps, NoContent, WidgetWrapper } from "shared-ui";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      min: 0,
      ticks: {
        maxTicksLimit: 7,
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  parsing: {
    xAxisKey: "key",
    yAxisKey: "value",
  },
};
const useGetAssetByUseState = () => {
  const api = useApi(AssetsByUseStateWidgetClient);

  return useQuery({
    queryKey: ["assets-by-use-state-widget"],
    queryFn: () => api.getAssetByUseStateData(),
  });
};

const AssetsByUseStateWidget: React.FC<BaseWidgetProps> = ({
  pageLayoutWidget,
}) => {
  const { data, isLoading } = useGetAssetByUseState();
  const barChartData = prepareChart(
    data?.assets ?? [],
    data?.useStateList ?? []
  );

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      isEmpty={!data?.assets?.length && !isLoading}
      cardHeaderDivider
    >
      {data?.assets?.length || isLoading ? (
        <Box style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <SingleDataWithLabels
            data={data?.totalAssets}
            label={"Total Assets"}
            loading={isLoading}
          />
          {isLoading ? (
            <Skeleton variant={"rectangular"} width={"100%"} height={300} />
          ) : (
            <div>
              <Bar data={barChartData} options={options} />
            </div>
          )}
        </Box>
      ) : (
        <NoContent
          containerHeight={320}
          header={"No Assets"}
          body={"You have no assets to view at the moment"}
          actionText={""}
          icon={<DevicesIcon />}
        />
      )}
    </WidgetWrapper>
  );
};

const prepareChart = (rawData: AssetByCategory[], useStateList: string[]) => {
  let datasets: any = [];
  const useStateStaticList = [
    "In Stock",
    "In Transit",
    "In Use",
    "In Maintenance",
    "Retired",
    "Unknown",
  ];
  const trimmedUseStateList = useStateStaticList.filter((item) =>
    useStateList.includes(item)
  );
  if (rawData.length) {
    datasets = rawData.map((data) => {
      const formatLabel =
        data.category &&
        data.category.charAt(0).toUpperCase() +
          data.category.slice(1).toLowerCase();
      return {
        label: formatLabel,
        data: data.assetsUseStates?.map((item) => ({
          key: item.useStateType,
          value: item.assetsCount,
        })),
        backgroundColor:
          categoryColors[data.category?.toLowerCase() ?? "unknown"] ??
          shiColors.gray.DEFAULT,
        borderColor:
          categoryColors[data.category?.toLowerCase() ?? "unknown"] ??
          shiColors.gray.light,
        borderWidth: 1,
      };
    });
  }
  return {
    labels: trimmedUseStateList,
    datasets: datasets,
  };
};
export default AssetsByUseStateWidget;
