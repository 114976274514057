import { LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import shiColors from "shared-ui/src/theme/shiColors";
import React from "react";

const css = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    textAlign: "right",
    width: "100%",
  } as React.CSSProperties,
  linearProgress: {
    height: "11px",
    background: "#A4C2DB",
    "& .MuiLinearProgress-bar": {
      background: "#1C64A5",
    },
  },
});

const numberFormatter = new Intl.NumberFormat("en-US");
export default function SubscriptionPurchaseProgress(
  props: LinearProgressProps & {
    used: number;
    total: number;
  }
) {
  const styles = css();
  const { used, total } = props;
  const percentageValue = Math.round((used / total) * 100);
  return (
    <div style={styles.container}>
      <div style={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={percentageValue}
          {...props}
          sx={styles.linearProgress}
        />
      </div>
      <div>
        <Typography
          style={{ color: shiColors.black, fontSize: "13px", opacity: 0.76 }}
        >{`${numberFormatter.format(used)}/${numberFormatter.format(
          total
        )} used (${percentageValue}%)`}</Typography>
      </div>
    </div>
  );
}
