import {
  CustomerSuccessPlan,
  CustomerSuccessPlanActivity,
  CustomerSuccessPlanActivityRequest,
  CustomerSuccessPlanClient,
  CustomerSuccessPlanCreationRequest,
  CustomerSuccessPlanProfileField,
  CustomerSuccessPlanProfileRequest,
  CustomerSuccessProgram,
  SuccessPlanActivityDocument,
  SuccessPlanMilestone,
} from "lib/ShiOneClient";
import { ApiClientConfig } from "../auth/ApiClientConfig";
const api = new CustomerSuccessPlanClient(
  ApiClientConfig,
  (window as any).config.REACT_APP_API_ENDPOINT
);

export async function getRestCustomerSuccessPlan(
  successPlanId: number
): Promise<CustomerSuccessPlan> {
  return api.getCustomerSuccessPlanById(successPlanId);
}

export async function getRestAllCustomerSuccessPlans(
  accountId: number
): Promise<CustomerSuccessPlan[]> {
  return api.getAllCustomerSuccessPlans(accountId);
}

export async function getRestCustomerSuccessPlanActivityId(
  successPlanActivityId: number
): Promise<CustomerSuccessPlanActivity> {
  return api.getCustomerSuccessPlanActivity(successPlanActivityId);
}

export async function postRestCustomerSuccessPlan(
  accountId: number,
  successPlanRequest: CustomerSuccessPlanCreationRequest
): Promise<CustomerSuccessPlan> {
  return api.createCustomerSuccessPlan(accountId, successPlanRequest);
}

export async function postRestCustomerProfile(
  customerSuccessPlanId: number,
  customerProfile: CustomerSuccessPlanProfileRequest
): Promise<CustomerSuccessPlanProfileField[]> {
  return api.updateCustomerSuccessPlanProfile(
    customerSuccessPlanId,
    customerProfile
  );
}

export async function postRestCustomerActivity(
  customerSuccessPlanId: number,
  activityId: number,
  activityRequest: CustomerSuccessPlanActivityRequest
): Promise<CustomerSuccessPlanActivity> {
  return api.updateCustomerSuccessPlanActivity(
    customerSuccessPlanId,
    activityId,
    activityRequest
  );
}

export async function deleteRestSuccessPlanActivityDocument(
  document: SuccessPlanActivityDocument
): Promise<CustomerSuccessPlanActivity> {
  return api.deleteSuccessPlanActivityDocument(document);
}

export async function getRestMilestones(): Promise<SuccessPlanMilestone[]> {
  return api.getSuccessPlanMilestones();
}

export async function getRestSuccessProgramProfile(
  successPlanProgramId: number
): Promise<CustomerSuccessPlanProfileField[]> {
  return api.getCustomerSuccessProgramProfile(successPlanProgramId);
}

export async function getCustomerSuccessPrograms(): Promise<
  CustomerSuccessProgram[]
> {
  return api.getCustomerSuccessPrograms();
}

export async function postRestUpdateCustomerSuccessPlanStartDate(
  planId: number,
  startDate: Date
): Promise<CustomerSuccessPlan> {
  return api.updateCustomerSuccessPlanStartDate(planId, startDate);
}
