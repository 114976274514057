import {
  hasAwsCloudContracts,
  hasAzureCloudContracts,
  hasGCPCloudContracts,
  hasOffice365CloudContracts,
} from "../../../../functions/contractHelper";
import { hasIntegrationsByType } from "../../../../functions/getCloudReportInformation";
import GlobalConstants from "@constants";
import { shallowEqual, useSelector } from "react-redux";
import { IRootState } from "../../../../modules";
import { useClaims } from "../../../../auth/ClaimsProvider";
import { CloudAccount } from "@lib/ShiOneClient";
import { IntegrationsCollectorState } from "../../../../modules/rtk/integrationsCollector/integrationsCollectorTypes";
import {
  assetItemsToShow,
  ciscoItemsToShow,
  cloudAwsItemsToShow,
  cloudAzureItemsToShow,
  cloudDatadogItemsToShow,
  cloudGcpItemsToShow,
  cloudOffice365ItemsToShow,
  homeItemsToShow,
  servicesItemsToShow,
  settingsItemsToShow,
  supportItemsToShow,
} from "./NavItemsToShowHelpers";
import { useFeatureFlag } from "../../../../containers/Modules/SHI/FeatureFlagHelper";

export function NavItemsToShow({
  cloudContracts,
  teamContracts,
  integrationsCollection,
  hasAzureCloudReports,
  fsaId,
  myManagedSecurityGroups,
  serviceReportsEnabled,
}: {
  cloudContracts: CloudAccount[];
  teamContracts: any;
  integrationsCollection: IntegrationsCollectorState;
  hasAzureCloudReports: any;
  fsaId: number | undefined;
  myManagedSecurityGroups: any[];
  serviceReportsEnabled: boolean;
}) {
  const { hasLegacyPermission, hasPermission, hasRoleIn, getClaimValue } =
    useClaims();
  const myDevices = useSelector(
    (state: IRootState) => state.device.myDevices,
    shallowEqual,
  );
  const { enabled: useTagGroupsPage } = useFeatureFlag(
    "revenue.cloud-tag-groups",
    false,
  );

  const { backupStats } = useSelector((state: IRootState) => state.backup);
  const hasBackupService = backupStats && backupStats.length > 0;

  const isAdmin = hasRoleIn("CustomerAdmin", "ShiAdmin");
  const contosoDemo =
    getClaimValue("accountId") ===
    GlobalConstants.demoAccounts.contoso.toString();

  const hasDevices = myDevices.length > 0;

  const hasAzureContracts = hasAzureCloudContracts(cloudContracts);
  const hasAwsContracts = hasAwsCloudContracts(cloudContracts);
  const hasOffice365Contracts = hasOffice365CloudContracts(cloudContracts);
  const hasGcpContracts = hasGCPCloudContracts(teamContracts, cloudContracts);
  const hasCloudAccounts =
    hasAzureContracts ||
    hasAwsContracts ||
    hasOffice365Contracts ||
    hasGcpContracts;

  const hasAzureComplianceIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureCompliance,
  );
  const hasAwsBillingReportIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.awsBillingReport,
  );
  const hasAzureBackupIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureBackups,
  );
  const hasAzureGovernanceReportsIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.azureGovernanceReports,
  );
  const hasGcpUsageReportIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.gcpUsageReport,
  );
  const hasCiscoCRRIntegration = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.ciscoReadyReport,
  );
  const hasCiscoCCIntegration = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.ciscoContractCoverage,
  );
  const hasDatadogUsageIntegration = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.datadogUsageReport,
  );
  const hasIdcsReportIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.idcsReport,
  );
  const hasDattoRmmIntegration = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.dattoRmm,
  );
  const hasHardwareLifecycleManagementIntegrations = hasIntegrationsByType(
    integrationsCollection,
    GlobalConstants.integrationType.hardwareLifecycleManagement,
  );

  const hasCiscoIntegrations = hasCiscoCRRIntegration || hasCiscoCCIntegration;

  const hasAzureMultiCloudReport =
    hasAzureCloudReports && hasAzureCloudReports.hasAzurePlanReportIntegration;

  const hasAwsReports = hasAwsBillingReportIntegrations;

  const hasGcpReports = hasGcpUsageReportIntegrations;

  const hasCloudReports =
    hasAzureMultiCloudReport ||
    hasAwsReports ||
    hasGcpReports ||
    !!hasDatadogUsageIntegration;

  const cloudAwsItemsToShowRules = cloudAwsItemsToShow({
    hasPermission,
    cloudContracts,
    isAdmin,
  });
  const cloudAzureItemsToShowRules = cloudAzureItemsToShow({
    hasPermission,
    hasAzureBackupIntegrations,
    hasAzureComplianceIntegrations,
    hasAzureGovernanceReportsIntegrations,
  });
  const cloudGcpItemsToShowRules = cloudGcpItemsToShow({
    hasPermission,
  });
  const cloudOffice365ItemsToShowRules = cloudOffice365ItemsToShow(
    hasPermission,
    hasBackupService,
  );
  const cloudDatadogItemsToShowRules = cloudDatadogItemsToShow(
    hasLegacyPermission,
    isAdmin,
    hasDatadogUsageIntegration,
    contosoDemo,
  );
  const showCloud =
    Object.entries(cloudAwsItemsToShowRules).some(([_k, v]) => v) ||
    Object.entries(cloudAzureItemsToShowRules).some(([_k, v]) => v) ||
    Object.entries(cloudGcpItemsToShowRules).some(([_k, v]) => v) ||
    Object.entries(cloudOffice365ItemsToShowRules).some(([_k, v]) => v) ||
    Object.entries(cloudDatadogItemsToShowRules).some(([_k, v]) => v);
  return {
    homeItemsToShow: homeItemsToShow(
      hasPermission,
      hasLegacyPermission,
      hasCloudReports,
      isAdmin,
    ),
    supportItemsToShow: supportItemsToShow(
      hasRoleIn,
      hasDevices,
      serviceReportsEnabled,
    ),
    assetItemsToShow: assetItemsToShow(
      hasLegacyPermission,
      hasHardwareLifecycleManagementIntegrations,
      hasIdcsReportIntegrations,
      hasDattoRmmIntegration,
      hasCiscoIntegrations,
    ),
    ciscoItemsToShow: ciscoItemsToShow(
      hasLegacyPermission,
      isAdmin,
      hasCiscoIntegrations,
    ),
    showCloud,
    showTagGroups: useTagGroupsPage ?? false,
    cloudAwsItemsToShow: cloudAwsItemsToShowRules,
    cloudAzureItemsToShow: cloudAzureItemsToShowRules,
    cloudGcpItemsToShow: cloudGcpItemsToShowRules,
    cloudOffice365ItemsToShow: cloudOffice365ItemsToShowRules,
    cloudDatadogItemsToShow: cloudDatadogItemsToShowRules,
    servicesItemsToShow: servicesItemsToShow(hasPermission, isAdmin, fsaId),
    settingsItemsToShow: settingsItemsToShow(
      isAdmin,
      myManagedSecurityGroups,
      hasCloudAccounts,
    ),
  };
}
