import {
  StyledDialogActions,
  StyledOkButton,
} from "@features/projects-feature/utils/components/StyledContractDetailsDialogComponents";
import React from "react";
import { ContractDetailsDialogCloseOnlyProps } from "@features/projects-feature/projectTypes";

export const ContractDetailDialogActions = ({
  onClose,
}: ContractDetailsDialogCloseOnlyProps) => {
  return (
    <StyledDialogActions>
      <StyledOkButton onClick={onClose} size={"small"}>
        OK
      </StyledOkButton>
    </StyledDialogActions>
  );
};
