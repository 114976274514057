import { GridRowId } from "@mui/x-data-grid-pro";
import { NavLink } from "react-router-dom";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { DateTime } from "luxon";
import { Chip, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import {
  inactiveColor,
  standardGraphColors,
} from "../../../../theme/siteColors";
import shiColors from "shared-ui/src/theme/shiColors";
import { lighten } from "@mui/material/styles";
import GlobalConstants from "@constants";
import {
  ContractDetailField,
  DetailFieldType,
} from "@features/projects-feature/projectTypes";
import { getPicklistValue } from "../../../../functions/picklistValue";
import { ContractDto } from "@lib/ShiOneClient";

const numberType = "number";

export const getContractDetailPicklistLabel = (
  field: ContractDetailField,
  contract: ContractDto,
  picklist: any
): string => {
  const value = contract[field.fieldKey];
  return typeof value === numberType
    ? getPicklistValue(
        picklist,
        field.picklistEntityKey,
        field.picklistFieldKey,
        value
      ).label
    : "";
};

export const formatDateTime = (dateTime: Date): string =>
  DateTime.fromJSDate(dateTime)
    .toUTC()
    .toFormat(GlobalConstants.MuiGridFormats.dateFormat);

export const formatDetailValue = (
  value: any,
  fieldType: DetailFieldType
): string => {
  switch (fieldType) {
    case DetailFieldType.Date:
      return value instanceof Date ? formatDateTime(value) : "";
    case DetailFieldType.Text:
      return value ? String(value) : "";
    case DetailFieldType.Number:
      return typeof value === numberType ? value.toString() : "";
    default:
      return "";
  }
};

export function assignProjectName(
  id: GridRowId,
  projectName: string,
  status: string
) {
  return (
    <NavLink
      to={AppendIfPrefixExists(`/services/projects/${id}`)}
      state={{ name: projectName, status: status }}
    >
      {projectName}
    </NavLink>
  );
}

export function assignStartDateTime(startDate: Date) {
  return <span>{formatDateTime(startDate)}</span>;
}

export function assignEndDateTime(endDate: Date) {
  return <span>{formatDateTime(endDate)}</span>;
}

export function assignDuration(
  duration: number,
  status: string,
  currentProgress: number
) {
  let barColor = shiColors.blue.light;
  let progress =
    (currentProgress / duration) * 100 > 100
      ? 100
      : Math.round((currentProgress / duration) * 100);

  let message =
    status === "Complete"
      ? status
      : `${currentProgress} / ${duration} (${progress}%)`;

  if (progress >= 100 && status !== "Complete") {
    message = `Overdue by ${currentProgress - duration} days`;
    barColor = shiColors.red.DEFAULT;
  }
  if (status === "Inactive") {
    message = status;
    progress = 100;
    barColor = shiColors.orange.light;
  }
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "10px",
          bgcolor: lighten(shiColors.blue.light, 0.6),
          "& .MuiLinearProgress-bar": { bgcolor: barColor, height: "10px" },
        }}
      />
      <Typography sx={{ float: "right", fontSize: ".8em" }}>
        {message}
      </Typography>
    </div>
  );
}

export function assignStatus(status: string) {
  return (
    <Chip
      size={"small"}
      sx={{ bgcolor: statusChipColors[status], color: "white" }}
      label={status}
    />
  );
}

export function assignType(type: string) {
  return (
    <Chip
      size={"small"}
      sx={{ bgcolor: typeChipColors[type], color: "white" }}
      label={type}
    />
  );
}

const statusChipColors: { [key: string]: string } = {
  Inactive: inactiveColor,
  New: standardGraphColors[1],
  "In Progress": standardGraphColors[2],
  "Change Order": standardGraphColors[3],
  "On Hold": standardGraphColors[4],
  "Waiting Parts": standardGraphColors[5],
  "At Risk": standardGraphColors[6],
  "Waiting Customer": standardGraphColors[7],
  Complete: standardGraphColors[8],
  Backlog: standardGraphColors[9],
};

const typeChipColors: { [key: string]: string } = {
  Proposal: standardGraphColors[1],
  Template: standardGraphColors[2],
  Internal: standardGraphColors[3],
  Client: standardGraphColors[4],
  Baseline: standardGraphColors[5],
};
