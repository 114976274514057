import React from "react";
import { ContractDetailDialogTitle } from "@features/projects-feature/components/ContractDetailDialogTitle";
import { ContractDetailDialogContent } from "@features/projects-feature/components/ContractDetailDialogContent";
import { ContractDetailDialogActions } from "@features/projects-feature/components/ContractDetailDialogActions";
import { ContractDetailDialogContainer } from "@features/projects-feature/components/ContractDetailDialogContainer";
import { useContractRecordsDetailFields } from "../utils/fields/ContractDetailsFields";
import { ContractDetailsDialogProps } from "@features/projects-feature/projectTypes";
import { Divider } from "@mui/material";

export const ContractDetailsDialog = ({
  open,
  onClose,
  contract,
  picklist,
}: ContractDetailsDialogProps) => {
  const RecordsDetail = useContractRecordsDetailFields(contract);

  return (
    <ContractDetailDialogContainer open={open} onClose={onClose}>
      <ContractDetailDialogTitle onClose={onClose} />
      <Divider />
      <ContractDetailDialogContent
        recordsDetail={RecordsDetail}
        contract={contract}
        picklist={picklist}
      />
      <Divider />
      <ContractDetailDialogActions onClose={onClose} />
    </ContractDetailDialogContainer>
  );
};
