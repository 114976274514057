export const widgetTableTheme = () => ({
  borderRadius: 0,
  borderLeft: "none",
  borderRight: "none",
  "& .MuiDataGrid-main": {
    overflow: "auto",
  },
  "& .MuiDataGrid-cell a": {
    fontWeight: 500,
  },
  "& .MuiDataGrid-cell:has(+ :not(.MuiDataGrid-cell))": {
    justifyContent: "flex-end",
    "& div": {
      textAlign: "right",
      justifyContent: "flex-end",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    overflowX: "hidden",
  },
});
