import { InputAdornment } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import React, { useEffect, useState } from "react";
import {
  ContractDetailField,
  DetailFieldType,
  RecordsDetail,
} from "@features/projects-feature/projectTypes";
import { ContractDto } from "@lib/ShiOneClient";
import { useGetProjects } from "@features/projects-feature/hooks/useGetProjects";
import { projectColors } from "@features/projects-feature/utils/projectColors";
import { useGetElasticServiceRequests } from "@features/service-requests-feature/hooks/useServiceRequests";
import { useGetContractInvoices } from "@features/projects-feature/hooks/useGetContractInvoices";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";

export const SearchParamAccessors = {
  contractName: "contractName",
  defaultView: "defaultView",
};

export const ContractDetailFields: ContractDetailField[] = [
  {
    label: "Contract",
    fieldKey: "contractName",
    inputProps: {
      readOnly: true,
    },
    type: DetailFieldType.Text,
    disabled: true,
    columnKey: 1,
    sortPriority: 1,
  },
  {
    label: "Contract Type",
    fieldKey: "contractServiceType",
    inputProps: {
      readOnly: true,
    },
    type: DetailFieldType.Text,
    columnKey: 1,
    sortPriority: 2,
  },
  {
    label: "Contract Service Level",
    fieldKey: "serviceLevelAgreementID",
    inputProps: {
      readOnly: true,
    },
    type: DetailFieldType.Text,
    columnKey: 1,
    sortPriority: 3,
    isPicklist: true,
    picklistEntityKey: "contract",
    picklistFieldKey: "ServiceLevelAgreementID",
  },
  {
    label: "Start Date",
    fieldKey: "startDate",
    inputProps: {
      readOnly: true,
      endAdornment: (
        <InputAdornment position="end">
          <DateRangeIcon
            sx={{ color: projectColors.contractDialog.dateRangeIcon }}
          />
        </InputAdornment>
      ),
    },
    type: DetailFieldType.Date,
    columnKey: 1,
    sortPriority: 4,
  },
  {
    label: "Contract Vendor",
    fieldKey: "contractVendor",
    inputProps: {
      readOnly: true,
    },
    type: DetailFieldType.Text,
    disabled: true,
    columnKey: 2,
    sortPriority: 1,
  },
  {
    label: "Contract Sub Type",
    fieldKey: "contractSubType",
    inputProps: {
      readOnly: true,
    },
    type: DetailFieldType.Text,
    columnKey: 2,
    sortPriority: 2,
  },
  {
    label: "Billing Type",
    fieldKey: "contractType",
    inputProps: {
      readOnly: true,
    },
    type: DetailFieldType.Text,
    columnKey: 2,
    sortPriority: 3,
    isPicklist: true,
    picklistEntityKey: "contract",
    picklistFieldKey: "ContractType",
  },
  {
    label: "End Date",
    fieldKey: "endDate",
    inputProps: {
      readOnly: true,
      endAdornment: (
        <InputAdornment position="end">
          <DateRangeIcon
            sx={{ color: projectColors.contractDialog.dateRangeIcon }}
          />
        </InputAdornment>
      ),
    },
    type: DetailFieldType.Date,
    columnKey: 2,
    sortPriority: 4,
  },
];

export const useContractRecordsDetailFields = (
  contract: ContractDto
): RecordsDetail[] => {
  const [contractProjectsCount, setContractProjectsCount] = useState(0);
  const [contractInvoicesCount, setContractInvoicesCount] = useState(0);

  const {
    data: projectsResponse,
    isLoading: projectsLoading,
    isError: projectsError,
  } = useGetProjects();

  const {
    data: contractInvoices,
    isLoading: contractInvoicesLoading,
    isError: contractInvoicesError,
  } = useGetContractInvoices(contract.id!);

  const request = {
    query: {
      sortBy: "CreatedDate",
      sortOrder: "desc",
      termFilters: {
        ContractName: contract.contractName,
      },
      dateFilters: {},
      searchTerm: "",
      resultsFrom: 0,
      pageSize: 1,
    },
    serviceRequestQueryType: "AllRequests",
  };

  const { data: serviceRequestsResponse } = useGetElasticServiceRequests(
    request as any
  );

  useEffect(() => {
    if (projectsResponse && !projectsError && !projectsLoading) {
      const projects =
        projectsResponse.projects && projectsResponse.projects.length > 0
          ? projectsResponse.projects.filter(
              (proj) => proj.contractID === contract.id
            )
          : [];
      setContractProjectsCount(projects.length);
    }
  }, [projectsResponse, projectsLoading, projectsError, contract.id]);

  useEffect(() => {
    if (
      contractInvoices &&
      !contractInvoicesLoading &&
      !contractInvoicesError
    ) {
      if (contractInvoices.length > 0) {
        setContractInvoicesCount(contractInvoices.length);
      }
    }
  }, [contractInvoices, contractInvoicesLoading, contractInvoicesError]);

  return [
    {
      title: "Requests",
      count: serviceRequestsResponse?.totalCount || 0,
      linkTo: `/support-center/requests?view=${prebuiltViewNames.allRequests}&ContractName=`,
      contractName: contract.contractName ?? ""
    },
    {
      title: "Projects",
      count: contractProjectsCount,
      linkTo: `/services/projects?${SearchParamAccessors.contractName}=`,
      contractName: contract.contractName ?? ""
    },
    {
      title: "Invoices",
      count: contractInvoicesCount,
      linkTo: `/services/billing?${SearchParamAccessors.contractName}=`,
      contractName: contract.contractName ?? ""
    },
  ];
};
