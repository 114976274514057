import { CircularProgress, Icon } from "@mui/material";
import React from "react";
import { NavItemType } from "../../NavigationTypes";
import MultiCloudConsumptionMenu from "../customers/MultiCloudConsumptionMenu";
import CloudMonitoringMenu from "../customers/CloudMonitoringMenu";
import { CloudAwsMenuV2 } from "./CloudAwsMenu";
import { CloudAzureMenuV2 } from "./CloudAzureMenu";
import { CloudGcpMenuV2 } from "./CloudGcpMenu";
import { CloudOffice365MenuV2 } from "./CloudOffice365Menu";
import { CloudDatadogMenu } from "./CloudDatadogMenu";
import { navItemsToShowType } from "../../NavItemsToShowTypes";

type CloudMenuParameters = {
  loadingBackupStats: boolean;
  showCloud: boolean;
  navItemsToShow: navItemsToShowType;
};
export function CloudMenu({
  loadingBackupStats,
  showCloud,
  navItemsToShow,
}: CloudMenuParameters): NavItemType {
  return {
    id: "cloud",
    type: "Group",
    displayName: "Cloud",
    icon: loadingBackupStats ? (
      <CircularProgress size={18} />
    ) : (
      <Icon className={"fas fa-cloud"} />
    ),
    visible: showCloud,
    children: [
      MultiCloudConsumptionMenu(navItemsToShow.homeItemsToShow),
      CloudMonitoringMenu(navItemsToShow.homeItemsToShow),
      {
        id: "tagGroups",
        type: "Link",
        displayName: "Tag Groups",
        path: "/cloud/tag-groups",
        visible: navItemsToShow.showTagGroups,
      },
      CloudAwsMenuV2(navItemsToShow.cloudAwsItemsToShow),
      CloudAzureMenuV2(navItemsToShow.cloudAzureItemsToShow),
      CloudGcpMenuV2(navItemsToShow.cloudGcpItemsToShow),
      CloudOffice365MenuV2(navItemsToShow.cloudOffice365ItemsToShow),
      CloudDatadogMenu(navItemsToShow.cloudDatadogItemsToShow),
    ],
  };
}
