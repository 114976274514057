import {
  formatDetailValue,
  getContractDetailPicklistLabel,
} from "@features/projects-feature/projectHelpers";
import { ContractDto } from "@lib/ShiOneClient";
import { TextField } from "@mui/material";
import React from "react";
import { ContractDetailTextFieldProps } from "@features/projects-feature/projectTypes";

export const ContractDetailTextField = ({
  field,
  contract,
  picklist,
}: ContractDetailTextFieldProps) => {
  return (
    <TextField
      key={field.fieldKey}
      size={"small"}
      label={field.label}
      variant="outlined"
      InputProps={field.inputProps}
      InputLabelProps={{ shrink: true }}
      value={
        field.isPicklist
          ? getContractDetailPicklistLabel(field, contract, picklist)
          : formatDetailValue(
              contract[field.fieldKey as keyof ContractDto],
              field.type
            )
      }
      fullWidth
      margin="dense"
    />
  );
};
