import RankingWidget, {
  RankingWidgetContainerHeight,
} from "@widgets/standard/RankingWidget";
import {
  DateFilterOption,
  PageLayoutWidgetDto,
  ServiceRequestClient,
  ServiceRequestIssueRankingsWidgetDto,
  ServiceRequestSubIssueRankingDto,
} from "@lib/ShiOneClient";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import React, { CSSProperties, useEffect, useState } from "react";
import {
  useWidgetFilterUserConfig,
  NoContent,
} from "../../dashboards/framework";
import { GridRenderCellParams, GridRowId } from "@mui/x-data-grid-pro";
import theme from "../../../../theme";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";
import { DateFilterOptionHeaderAction } from "@widgets/utils/support/DateFilterOptionHeaderAction";
import { getDateRangeForFilter } from "@features/service-requests-feature/utils/serviceRequestFunctions";
import LabelLink from "shared-ui/src/components/LabelLink";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { RequestsSearchParamAccessors } from "@features/service-requests-feature/utils/constants";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import { SkeletonWrapper } from "shared-ui";

const filterKey = "issue-rankings-widget-date-filter";
const IssueRankingLabelLinkStyles: CSSProperties = {
  display: "inline-flex",
};

export default function IssueRankingsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const [gridData, setGridData] = useState<any>([]);
  const [rawData, setRawData] = useState<{
    [key: string]: ServiceRequestSubIssueRankingDto;
  }>({});
  const [totalRecords, setTotalRecords] = useState(0);
  const { currentFilterValue, setFilter, isLoadingFilterUserConfig } =
    useWidgetFilterUserConfig(
      pageLayoutWidget.widgetId!,
      filterKey,
      DateFilterOption.CurrentMonth
    );

  let responseFunc = useGetIssueRankingsWidgetsData(currentFilterValue);
  let loading = responseFunc.status === "loading";

  useEffect(() => {
    setRawData(
      responseFunc.data?.issueRankings ??
        ({} as { [key: string]: ServiceRequestSubIssueRankingDto })
    );
    setTotalRecords(responseFunc.data?.totalRecordsCount ?? 0);
  }, [responseFunc.data]);

  useEffect(() => {
    setGridData(prepareData(rawData));
  }, [rawData]);

  const groupingColDef = {
    sortable: true,
    headerName: "Issue",
    minWidth: 180,
    flex: 0.3,
    hideable: false,
    disableReorder: true,
    valueGetter: (value: string, row: any) => {
      return getIssueLabelLink(
        row.issue,
        currentFilterValue,
        row.hierarchy.length > 1,
        loading
      );
    },
    renderHeader: (params: any) => {
      return (
        <div style={{ marginLeft: "45px" }}>{params.colDef.headerName}</div>
      );
    },
  };

  const columnDefinitions = [
    {
      id: "requests",
      headerName: "Requests",
      sortable: true,
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        return gridData ? (
          handleColoredCell(
            params.value,
            params.id,
            params.row,
            Object.keys(rawData).length,
            totalRecords
          )
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <RankingWidget
      pageLayoutWidget={pageLayoutWidget}
      headerAction={
        <DateFilterOptionHeaderAction
          filterBy={currentFilterValue}
          setFilterBy={setFilter}
          loading={isLoadingFilterUserConfig}
          filterKey={filterKey}
        />
      }
      data={gridData}
      gridDefinitions={{ columnDefinitions, groupingColDef }}
      treeData={true}
      rowExpandLookup={React.useRef({})}
      noDataElement={<NoDataElement />}
      loading={loading}
    />
  );
}

function useGetIssueRankingsWidgetsData(currentFilterValue: DateFilterOption) {
  const api = useApi(ServiceRequestClient);
  const dateRange = getDateRangeForFilter(currentFilterValue);

  let dateFrom = dateRange.startDate;
  let dateTo = dateRange.endDate;

  return useQuery<ServiceRequestIssueRankingsWidgetDto>(
    ["issueRankingsWidget", dateFrom, dateTo],
    () => api.issueRankingsWidget(dateFrom, dateTo)
  );
}

function NoDataElement() {
  return (
    <NoContent
      containerHeight={RankingWidgetContainerHeight}
      header={"No Requests"}
      body={
        "Create a new request to report an incident or submit a service request"
      }
      actionText={"Get Support"}
      actionLink={"/support-center/new-request"}
    />
  );
}

let handleColoredCell = (
  value: number,
  index: GridRowId,
  row: any,
  count: number,
  totalRecords: number
) => {
  let red = theme.palette.red;
  let ratio = 1 - Number((Number(row.parentIndex) / count).toFixed(3));
  let color = row.hierarchy.length > 1 ? "255, 255, 255" : red;
  let generatedColor = `rgba(${color}, ${ratio * 0.2})`;
  let percentage = ((value / totalRecords) * 100).toFixed(2);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        backgroundColor: `${generatedColor}`,
        width: "100%",
        height: "100%",
      }}
    >
      {numberFormatter.format(value)} ({percentage}%)
    </div>
  );
};

const getIssueLabelLink = (
  issue: string,
  currentFilterValue: string,
  isSubIssue: boolean,
  loading: boolean
) => {
  let issueRankingsQueryParams = {
    [RequestsSearchParamAccessors.view]: prebuiltViewNames.allRequests,
    [RequestsSearchParamAccessors.createdDate]: currentFilterValue,
  };
  if (isSubIssue) {
    issueRankingsQueryParams = {
      ...issueRankingsQueryParams,
      [RequestsSearchParamAccessors.subIssueType]: issue,
    };
  } else {
    issueRankingsQueryParams = {
      ...issueRankingsQueryParams,
      [RequestsSearchParamAccessors.issueType]: issue,
    };
  }

  return (
    <SkeletonWrapper loading={loading}>
      <LabelLink
        label={issue}
        baseUrl={"/support-center/requests"}
        queryParams={issueRankingsQueryParams}
        hashes={[ServiceRequestsPageTabs.allRequests]}
        css={IssueRankingLabelLinkStyles}
      />
    </SkeletonWrapper>
  );
};

let prepareData = (rawData: {
  [key: string]: ServiceRequestSubIssueRankingDto;
}) => {
  let data: {}[] = [];
  let key = 0;
  Object.keys(rawData).forEach((k, i) => {
    data.push({
      hierarchy: [k],
      id: key++,
      issue: k,
      requests: rawData[k].totalRecords,
      parentIndex: i,
    });
    let subIssues = rawData[k]?.subIssueRankings ?? {};
    Object.keys(subIssues).forEach((z) => {
      data.push({
        hierarchy: [k, z],
        id: key++,
        issue: z,
        requests: subIssues[z],
        parentIndex: i,
      });
    });
  });
  return data;
};
