import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import shiColors from "shared-ui/src/theme/shiColors";
import { projectColors } from "@features/projects-feature/utils/projectColors";

export const StyledRecordsDetailContainer = styled(Grid)({
  padding: "16px 0px 32px 0px",
  height: "166px",
  marginBottom: "20px",
  justifyContent: "space-between",
});

export const StyledRecordsDetailItemContainer = styled(Box)(({ theme }) => ({
  padding: "16px",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "8px",
}));

export const StyledRecordsDetailItemTitle = styled(Typography)({
  color: projectColors.contractDialog.subheader,
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
});

export const StyledRecordsDetailItemCount = styled(Typography)({
  color: projectColors.contractDialog.recordDetails.recordCount,
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "32px",
  letterSpacing: "0.15px",
});

export const StyledRecordsDetailItemLinkTitle = styled(Typography)(
  ({ count }: { count: number }) => ({
    color:
      count > 0
        ? shiColors.blue.light
        : projectColors.contractDialog.recordDetails.disabledLink,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
  })
);
