import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import React, { Fragment } from "react";
import { NavFunctionStyles } from "../styles/NavigationStyles";
import { NavItemType } from "./NavigationTypes";

interface NavFunctionProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}
export default function NavFunction({
  navItem,
  tier,
  parentContext,
}: NavFunctionProps) {
  const theme = useTheme();
  const styles = NavFunctionStyles(theme);

  if (!navItem.visible) {
    return <Fragment />;
  }
  return (
    <>
      <ListItemButton
        sx={[tier === 2 ? styles.nested : {}, tier === 3 ? styles.nested2 : {}]}
        onClick={() => (navItem.function ? navItem.function() : null)}
        href={AppendIfPrefixExists(navItem.path)}
        data-telemetry-name={`${parentContext} > ${navItem.displayName}`}
      >
        {navItem.icon && (
          <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={navItem.displayName}
          sx={tier === 1 ? styles.listItemText : {}}
        />
      </ListItemButton>
    </>
  );
}
