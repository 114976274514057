import { homeItemsToShowType } from "../../NavItemsToShowTypes";

export default function CloudMonitoringMenu(
  itemsToShow: homeItemsToShowType
) {
  return {
    id: "monitoring",
      type: "Link",
    displayName: "Multicloud Monitoring",
    path: "/dashboards/monitoring",
    visible: itemsToShow.showMonitoring,
  };
}
