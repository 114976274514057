import React from "react";
import {
  PageLayoutWidgetDto,
  SupportRequestWidgetsClient,
  ServiceRequestCompletedMeetingSlaWidgetDto,
} from "@lib/ShiOneClient";
import {
  timeOptionKeys,
  timeOptions,
  timeOptionTypes,
} from "@widgets/utils/DateRanges";
import { useApi } from "../../utils";
import { useQuery } from "@tanstack/react-query";
import { ToggleButtonGroup } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import shiColors from "shared-ui/src/theme/shiColors";
import { Summary } from "../../interfaces/WidgetInterfaces";
import { prebuiltViewNames } from "@features/service-requests-feature/components/prebuiltViews";
import { ServiceRequestsPageTabs } from "@features/service-requests-feature/components/ServiceRequestsPageTabs";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import PercentageLine from "../../components/PercentageLine";
import { numberFormatter } from "@features/assets-feature/utils/assetInventoryFunctions";
import { useWidgetFilterUserConfig } from "../../dashboards/framework";
import {
  NoContent,
  WidgetWrapper,
} from "shared-ui";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      max: 100,
      min: 0,
      ticks: {
        callback: function (value: any) {
          return value + "%";
        },
        maxTicksLimit: 6,
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom" as const,
    },
  },
};

const filterKey = "completed-requests-meeting-sla-widget";
export default function IssueRankingsWidget({
  pageLayoutWidget,
}: {
  pageLayoutWidget: PageLayoutWidgetDto;
}) {
  const { currentFilterValue, setFilter } = useWidgetFilterUserConfig(
    pageLayoutWidget.widgetId!,
    filterKey,
    "1M"
  );
  let responseFunc = useGetCompletedRequestsData(currentFilterValue);

  let timeOptionsFiltered = Object.keys(timeOptions).filter((to) => {
    return (
      to === timeOptionKeys.oneMonth ||
      to === timeOptionKeys.threeMonth ||
      to === timeOptionKeys.sixMonth
    );
  });

  let chartData = getChartData(
    responseFunc.data?.data ??
      ({} as ServiceRequestCompletedMeetingSlaWidgetDto)
  );

  let totalMeetingSLA = responseFunc.data?.data?.totalMeetingSla ?? 0;
  let totalNotMeetingSLA = responseFunc.data?.data?.totalNotMeetingSla ?? 0;

  let hasData = totalMeetingSLA + totalNotMeetingSLA > 0;

  let percentageLineData = [
    {
      data: totalMeetingSLA,
      label: "Meeting SLA",
      color: shiColors.green.DEFAULT,
    },
    {
      data: totalNotMeetingSLA,
      label: "Not Meeting SLA",
      color: shiColors.red.DEFAULT,
    },
  ] as Summary[];

  let percentageLineCustomValue = `${numberFormatter.format(
    totalMeetingSLA
  )}/${numberFormatter.format(
    totalMeetingSLA + totalNotMeetingSLA
  )} (${Math.round(
    (totalMeetingSLA / (totalMeetingSLA + totalNotMeetingSLA)) * 100
  )}%)`;

  return (
    <WidgetWrapper
      pageLayoutWidget={pageLayoutWidget}
      headerAction={
        <ToggleButtonGroup
          size="small"
          color="secondary"
          exclusive
          value={currentFilterValue}
          onChange={(e, value) => {
            if (value === null) return;
            setFilter(value);
          }}
        >
          {timeOptionsFiltered.map((k, i) => {
            return (
              <ToggleButton value={k} key={i}>
                {k}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      }
      isEmpty={!hasData && !responseFunc.isLoading}
    >
      {hasData || responseFunc.isLoading ? (
        <>
          <PercentageLine
            visualParts={percentageLineData}
            loading={responseFunc.isLoading}
            leftLabel={"Requests Meeting First SLA"}
            showLabels={false}
            customValue={percentageLineCustomValue}
          />
          <div>
            <Bar data={chartData} options={options} />
          </div>
        </>
      ) : (
        <NoContent
          containerHeight={350}
          header={"No Requests Completed"}
          body={"You have no requests that were completed for this time period"}
          actionText={"View All Requests"}
          actionLink={AppendIfPrefixExists(
            `/support-center/requests?view=${prebuiltViewNames.allRequests}#${ServiceRequestsPageTabs.allRequests}`
          )}
        />
      )}
    </WidgetWrapper>
  );
}

function getChartData(
  data: ServiceRequestCompletedMeetingSlaWidgetDto
): ChartData<"bar", number[], string | string[]> {
  if (!data || !data.responseTypes) {
    return {
      labels: [],
      datasets: [],
    };
  }
  let labels = data.responseTypes?.map((rt) =>
    rt.responseType ? rt.responseType.split(" ") : ""
  );
  return {
    labels,
    datasets: [
      {
        label: "Meeting SLA",
        data: data.responseTypes?.map(
          (rt) =>
            Math.round(
              ((rt.meetingSla ?? 0) /
                ((rt.meetingSla ?? 0) + (rt.notMeetingSla ?? 0))) *
                100 *
                1e2
            ) / 1e2
        ),
        backgroundColor: shiColors.green.DEFAULT,
        borderColor: shiColors.green.DEFAULT,
        borderWidth: 1,
      },
      {
        label: "Not Meeting SLA",
        data: data.responseTypes?.map(
          (rt) =>
            Math.round(
              ((rt.notMeetingSla ?? 0) /
                ((rt.meetingSla ?? 0) + (rt.notMeetingSla ?? 0))) *
                100 *
                1e2
            ) / 1e2
        ),
        backgroundColor: shiColors.red.DEFAULT,
        borderColor: shiColors.red.DEFAULT,
        borderWidth: 1,
      },
    ],
  };
}

function useGetCompletedRequestsData(currentFilterValue: timeOptionTypes) {
  const api = useApi(SupportRequestWidgetsClient);

  let dateFrom = timeOptions[currentFilterValue].dateFrom;
  let dateTo = timeOptions[currentFilterValue].dateTo;
  return useQuery(["completedRequestMeetingSla", dateFrom, dateTo], () =>
    api.getCompletedRequestsMeetingSlaWidget(dateFrom, dateTo)
  );
}
