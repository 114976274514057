import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { UserConfigDto } from "lib/ShiOneClient";
import {
  deleteUserConfig,
  getUserConfigs,
  postUserConfig,
} from "@api/userSettingsStore";
import ms from "ms";

export const cacheKey = "UserConfigs";

export interface UserConfigResponse {
  userConfig: UserConfigDto | undefined;
  isLoading: boolean;
  error: Error | null;
}

export const useGetUserConfig = (settingsKey: string): UserConfigResponse => {
  const { data: userConfigs, error, isLoading } = useGetUserConfigs();
  const userConfig = userConfigs?.find(
    (config) => config.settingsKey === settingsKey
  );
  return { userConfig, isLoading, error };
};

const useGetUserConfigs = () => {
  return useQuery<UserConfigDto[], Error>({
    queryKey: [cacheKey],
    queryFn: () => getUserConfigs(),
    staleTime: ms("10m"),
  });
};

export const useUpsertUserConfig = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, UserConfigDto>(
    async (userConfigDto) => {
      await postUserConfig(userConfigDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([cacheKey]).then();
      },
    }
  );
};

export const useDeleteUserConfig = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, UserConfigDto>(
    async (userConfigDto) => {
      await deleteUserConfig(userConfigDto);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([cacheKey]).then();
      },
    }
  );
};
