import { NavItemType } from "./NavigationTypes";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavItem from "./NavItem";
import React, { useState } from "react";
import { NavGroupStyles } from "../styles/NavigationStyles";
import { AppendIfPrefixExists } from "../../../../modules/sitePaths";
import { Link } from "react-router-dom";

interface NavDashboardProps {
  navItem: NavItemType;
  tier: number;
  parentContext: string;
}
export default function NavDashboard({
  navItem,
  tier,
  parentContext,
}: NavDashboardProps) {
  const theme = useTheme();
  const styles = NavGroupStyles(theme);

  const [open, setOpen] = useState(false);

  const handleDropDownChange = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.preventDefault();
    setOpen(!open);
  };

  let telemetryContext = `${parentContext} > ${navItem.displayName}`;
  return (
    <>
      <ListItemButton
        component={Link}
        to={AppendIfPrefixExists(navItem.path)}
        data-telemetry-name={telemetryContext}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {navItem.icon && (
          <ListItemIcon sx={styles.listItemIcon}>{navItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={navItem.displayName}
          sx={[
            tier === 1 ? styles.listItemText : {},
            tier === 2 ? styles.tier2 : {},
          ]}
          title={navItem.displayName}
        />
        {navItem.children && navItem.children.some((child) => child.visible) ? (
          open ? (
            <ExpandLess onClick={(e) => handleDropDownChange(e)} />
          ) : (
            <ExpandMore onClick={(e) => handleDropDownChange(e)} />
          )
        ) : (
          <></>
        )}
      </ListItemButton>
      {navItem.children && navItem.children.length > 0 && (
        <>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {navItem.children.map((child, i) => (
                <NavItem
                  key={i}
                  navItem={child}
                  tier={tier + 1}
                  parentContext={telemetryContext}
                />
              ))}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
}
